import MapComponent from "./MapComponent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from "./Footer";
import SearchBar from "./SearchBar";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadIcon from '@mui/icons-material/Download';
import ListIcon from '@mui/icons-material/List';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Speedometer from 'react-d3-speedometer';
import Slider from '@mui/material/Slider';
import _ from "lodash";

// Top Marketing
// Strong Negoitaors
// Experiaced Team
// Stagging & Photography
// Resources
// Contact Your Realtor

class UltimateGuides extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      selectedEntry:  1,
      pictureHeight: "100%",
      pictureWidth: "60%",
      communities:[
        "Financing",
        "Pre Listing",
        "Buyer Closing",
        "Seller Closing",
        "Final Walkthrough",
        "Open House",
        "House Hunting",
        "Staging & Photography",
        "Selling Process",
        "Buying Process"
      ],

      reportDate: "",
      medForSale: 0,
      medDaysOnMarket: 0,
      medPrice: 0,
      medSqf: 0,
      inProgres: false,

      UltimateGuidesTiles: [
        {
          id: "1",
          name: "Financing",
          download: "financing-checklist",
          image:"financing"
        },
        {
          id: "2",
          name: "Pre Listing",
          download: "prelisting-checklist",
          image:"pre-listing"
        },
        {
          id: "3",
          name: "Buyer Closing",
          download: "buyers-closing-checklist",
          image:"buyers-closing"
        },
        {
          id: "4",
          name: "Seller Closing",
          download: "sellers-closing-checklist",
          image:"sellers-closing"
        },
        {
          id: "5",
          name: "Final Walkthrough",
          download: "final-walkthrough-checklist",
          image:"final-walkthrough"
        },
        {
          id: "6",
          name: "Open House",
          download: "open-house-checklist",
          image:"open-house"
        },
        {
          id: "7",
          name: "House Hunting",
          download: "house-hunting-checklist",
          image:"house-hunting"
        },
        {
          id: "8",
          name: "Staging",
          download: "staging-checklist",
          image:"staging-photography"
        },
        {
          id: "9",
          name: "Selling Process",
          download: "home-selling-process",
          image:"selling-process"
        },
        {
          id: "10",
          name: "Buying Process",
          download: "home-buying-process",
          image:"buying-process"
        },
      ],      
    }
  }

  getUltimateGuide = () => {
  }
      

  downloadUltimateGuide = () => {
      this.setState({ inProgress: true, generateReport: true, alertDialogOpen: true }, () => {
          this.props.getUltimateGuideDownload(
          this.props.parentPage,
          this.state.UltimateGuidesTiles[this.state.selectedEntry-1].download,
          this.downloadUltimateGuideCallback
          )});
  }
        

  downloadUltimateGuideCallback  = (data) => {

    // Create a URL for the blob object
    const url = window.URL.createObjectURL(data);
      
    // Create a new link element
    const link = document.createElement('a');

    // Set the link's href attribute to the URL
    link.href = url;

    // Set the link's download attribute to the desired file name
    link.download = this.state.UltimateGuidesTiles[this.state.selectedEntry-1].download + "-ultimate-guide.jpg";

    link.target = '_blank';
    
    // Click the link to initiate the download
    link.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(url);

    this.setState({inProgress:false, generateReport:false})
  }

  toggleUltimateGuides = () => {
    this.setState({
      UltimateGuides: !this.state.UltimateGuides,
    });
  };

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };

  toggleRequestForm = (listing) => {
    this.setState({
      showListing: this.props.listings[0],
      contactForm: !this.state.contactForm,
      contactReason: "Request A Showing",
    });
  };

  getSummaryTile = () => {

    return (
      <React.Fragment>
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                fontSize:".75rem",
                textAlign:"left",
                color:"#888888"
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
                animationType={null}
                animationTime={null}
                tagPicture={null}
                tagTxt={null}
                tagTxtColor={"black"}
                tagTxtSize={["1rem", "1rem", "16px"]}
                tagTxtType={"bold"}
                tagTxtAlign={"center"}
                tagVerticalAlign={"flex-start"}
                tagHorizontalAlign={"flex-end"}
                tagBgColor={"#73be73"}
                tagBorder={"1px solid black"}
                tagBorderRadius={"15px"}
                topPadding={"5px"}
                topPicture={null}
                topPictureAlign={null}
                topPictureSize={"contain"}
                topTxt={"My Ultimate Guides"}
                topTxtColor={this.props.theme.bannerTextColor}
                topTxtSize={["1.2rem", "1rem", "1.2rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={this.props.theme.bannerColor}
                topHeight={["50px", "10%", "10%"]}
                topHorizontalAlign={"center"}
                topVerticalAlign={"center"}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"10px"}
                mdlPicture={"downloads/ultimate-guides-shadow.png" }
                mdlPictureAlign={"50% 50%"}
                mdlPictureSize={"contain"}
                mdlTxt={null}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", ".75rem", "1rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"rgba(255,255,255,0)"}
                mdlBorder={"0px solid black"}
                mdlHeight={["250px", "50%", "50%"]}
                mdlGraphType={"line"}
                mdlGraphColors={["red","blue","gold","green"]}
                mdlGraphData={null}
                mdlGraphLegend={false}
                mldGraphGrid={false}                
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={"My custom-made guides are designed to simplify the buying and selling of real estate. Tailored to your unique needs, these guides provide clear, step-by-step instructions, checklists, and valuable insights to help you navigate the market.  Along with my personalized guidance, we’ll make informed decisions with confidence."}
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["15px", ".75rem", "1rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"left"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"flex-start"}
                btmVerticalAlign={"flex-start"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["150px", "40%", "40%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
              
            </Grid>
      </React.Fragment>
    );
  };

  downloadResources = (filename) => {
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }


  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,.75)",
          height: "100%",
          width: "100%",
          borderBottom: "1px solid #333333",
        }}
      >
                <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "5%",
              lg: "5%",
              md: "5%",
              sm: "5%",
            },
            padding: "5px",
            fontSize: "2rem",
            borderBottom: "0px solid #333333",
          }}
          xs={12}
        >


          </Grid>

        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "85%",
              lg: "85%",
              md: "85%",
              sm: "85%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{              
              height: "100%",
              width: "100%",
            }}
          >

            {this.getSummaryTile()}
            {this.getUltimateGuideListTile()}
            {this.getCommunityInfoTile(this.state.selectedEntry ? this.state.UltimateGuidesTiles[this.state.selectedEntry-1]: null)}

          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "5px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Footer theme={this.props.theme}  />
        </Grid>
      </Grid>
    );
  };

  getUltimateGuideListTile = () => {
    return (
      <React.Fragment>
        <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["1rem", "1rem", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"15px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={"My Most Requested Guides"}
            topTxtColor={this.props.theme.bannerTextColor}
            topTxtSize={["1.2rem", "1.25rem", "1.25rem"]}
            topTxtType={"bold"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={this.props.theme.bannerColor}
            topHeight={["50px", "10%", "10%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"5px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={
              <Grid container sx={{overflowX:"hidden", width:"100%", height:"100%", padding:"0px"}} >
              <Grid item xs={12}>
              <Grid container sx={{width:"100%", height:"100%", padding:"0px", border:"0px solid black"}} >
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 1 }, () => this.getUltimateGuide() )}}
                  sx={{
                    paddingTop:"5px",
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 1 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 1 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"5px"}}  alignItems="center" justifyContent="center">{this.state.communities[0]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 2 }, () => this.getUltimateGuide())}}
                  sx={{
                    paddingTop:"5px",
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 2 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 2 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"5px"}}  alignItems="center" justifyContent="center">{this.state.communities[1]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 3 }, () => this.getUltimateGuide())}}
                  sx={{
                    paddingTop:"5px",
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 3 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 3 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"5px"}}  alignItems="center" justifyContent="center">{this.state.communities[2]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 4 }, () => this.getUltimateGuide())}}
                  sx={{
                    paddingTop:"5px",
                      '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 4 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 4 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"5px"}}  alignItems="center" justifyContent="center">{this.state.communities[3]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 5 }, () => this.getUltimateGuide())}}
                  sx={{
                    paddingTop:"5px",
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 5 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 5 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"5px"}}  alignItems="center" justifyContent="center">{this.state.communities[4]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 6 }, () => this.getUltimateGuide())}}
                  sx={{
                    paddingTop:"5px",
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 6 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 6 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"5px"}}  alignItems="center" justifyContent="center">{this.state.communities[5]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 7 }, () => this.getUltimateGuide())}}
                  sx={{
                    paddingTop:"5px",
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 7 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 7 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"5px"}}  alignItems="center" justifyContent="center">{this.state.communities[6]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 8 }, () => this.getUltimateGuide())}}
                  sx={{
                    paddingTop:"5px",
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 8 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 8 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"5px"}}  alignItems="center" justifyContent="center">{this.state.communities[7]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 9 }, () => this.getUltimateGuide())}}
                  sx={{
                    paddingTop:"5px",
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 9 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 9 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"5px"}}  alignItems="center" justifyContent="center">{this.state.communities[8]}</Grid></Grid>
                <Grid item 
                  onClick={() => {this.setState({selectedEntry: 10 }, () => this.getUltimateGuide())}}
                  sx={{
                    paddingTop:"5px",
                    '&:hover': {
                        border: "1px solid #DDDDDD",
                        cursor: 'pointer',
                      },fontWeight:"bold", background: this.state.selectedEntry === 10 ? "rgba(225,225,225,.5 )" : "rgba(225,225,225,0)", border: this.state.selectedEntry === 10 ? "1px solid #DDDDDD" :"1px solid #FFFFFF", padding:"2px 0px 2px 0px"}} xs={12}><Grid container sx={{width:"100%", height:"100%", padding:"5px"}}  alignItems="center" justifyContent="center">{this.state.communities[9]}</Grid></Grid>
              </Grid>
              </Grid>

          </Grid>
        
            }
            mdlTxtColor={this.props.theme.textColor}
            mdlTxtSize={["1rem", ".8rem", ".8rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"rgba(255,255,255,1)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["400px", "80%", "80%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={""}
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={["1rem", ".8rem", "1rem"]}
            btmTxtType={"bold"}
            btmTxtAlign={"center"}
            btmTxtLineHeight={"1.25"}
            btmOverflow={"hidden"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={"white"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "10%", "10%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );


  }

  getCommunityInfoTile = (tile) => {
    return (
      <Grid
      item
      sx={{
        width: "100%",
        padding: {
          xs:"10px",
          sm:"10px",
          md:"10px",
          lg:"20px",
          xl:"20px"
        },
        height: {
          xl: "100%",
          lg: "100%",
          md: "100%",
          sm: "100%",
        },
        
      }}
      xl={4}
      lg={4}
      md={4}
      sm={6}
      xs={12}
    >
               <CommonTile
                animationType={null}
                animationTime={null}
                tagTopLeftTxt={
                  this.state.inProgress ?                     
                  <div  style={{ padding: '0px 20px 0px 20px' }}>
                  <CircularProgress disableShrink size={12} sx={{color:this.props.theme.bannerTextColor, opacity:"1"}}/>
                  </div>
                  : this.state.selectedEntry >= 0 && tile.download ? "Download " + this.state.UltimateGuidesTiles[this.state.selectedEntry-1].name : ""
                }
                tagTopLeftPadding={"5px 10px 5px 10px"}
                tagTopLeftTxtColor={this.props.theme.bannerTextColor}
                tagTopLeftTxtSize={[".75rem", ".75rem", ".75rem"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"5px"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={this.props.theme.bannerColor}
                tagTopLeftBorder={"0px solid " + this.props.theme.bannerTextColor}
                tagTopLeftBorderRadius={["0px","20px","20px","0px"]}
                tagTopLeftCallback={!this.state.inProgress ? this.downloadUltimateGuide : null}                                

                topPadding={"0px"}
                topPicture={null}
                topPictureAlign={null}
                topPictureSize={null}
                topTxt={null}
                topTxtColor={"black"}
                topTxtSize={["1rem", "1rem", "1rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={["50px", "10%", "10%"]}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={"downloads/ultimate-guide-"+ this.state.UltimateGuidesTiles[this.state.selectedEntry-1].image +".png"}
                mdlPictureAlign={"50% 10%"}
                mdlPictureSize={"contain"}
                mdlTxt={null}
                mdlTxtColor={"black"}
                mdlTxtSize={["1rem", "1rem", "1.2rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"white"}
                mdlBorder={"0px solid black"}
                mdlHeight={["300px", "80%", "80%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                mdlProgress={this.state.inProgress}
                mdlProgressSize={40}
                mdlProgressColor={this.props.theme.bannerColor}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={"Call Me For An Indepth Discusson"}
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["1rem", ".8rem", "1rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"center"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["50px", "10%", "10%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
              </Grid>
    );
  };

  
  render() {

 
    return (
      <Drawer
          anchor="bottom"
          open={this.props.showUltimateGuides}
          onClose={this.props.toggleUltimateGuides}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "88%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",

              backgroundImage: "url('"+ this.props.theme.background +"')",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

              marginLeft: "auto",
              pointerEvents:"auto",
              zINdex:this.props.zindex
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"1px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "5px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                padding: "0px 0px 0px 5px",               
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem",

                },               
                color:this.props.theme.bannerTextColor
              }}
            >
             My Ultimate Guides
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact("BuyingSelling")}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "5px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                  
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.props.toggleUltimateGuides}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "0px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   
                {this.createSection(
                this.UltimateGuidesSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top UltimateGuidesage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                null,
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>
      </Grid>
      </Drawer>
    );
  }
}

export default UltimateGuides;
