import Grid from "@mui/material/Grid";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from "./Footer";
import Drawer from "@mui/material/Drawer";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import _ from "lodash";

// Top Relocation
// Strong Negoitaors
// Experiaced Team
// Stagging & Photography
// Resources
// Contact Your Realtor

class MyServicesRelocation extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      relocationInProgress:false,
      sellingAHomeTiles: [
        {
          id: "1",
          image: "downloads/my-services-relocation-tile.png",
          name: "Learn More About My Relocation Services",
          fullSize: true,
          summary:
            "My top priority is to provide exceptional service that exceeds your home buying or selling expectations. You can trust that I will guide you through each step of the process with confidence and expertise.  Click here to schedule an appointment.",
          download: "relocation",
        },
      ],

      selectedEntry:  0,
      pictureHeight: "100%",
      pictureWidth: "60%",
    }
  }


  toggleMyServicesRelocation = () => {
    this.setState({
      MyServicesRelocation: !this.state.MyServicesRelocation,
    });
  };

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };

  toggleRequestForm = (listing) => {
    this.setState({
      showListing: this.props.listings[0],
      contactForm: !this.state.contactForm,
      contactReason: "Request A Showing",
    });
  };



  downloadResources = (filename) => {

    this.setState({relocationInProgress:true}, () => this.props.getInformationDownload("MyServices", filename, this.callback))

    /*
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
    */
  }

  callback = (response) => {

    if(response.data.message){
      const base64String = response.data.message;

      // Convert base64 string to Blob
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });

      // Create a download link and trigger click
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = response.data.filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

    }
    this.setState({marketingInProgress:false})

  }


  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,.75)",
          height: "100%",
          width: "100%",
          borderBottom: "1px solid #333333",
        }}
      >
                <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "5%",
              lg: "5%",
              md: "5%",
              sm: "5%",
            },
            padding: "5px",
            fontSize: "2rem",
            borderBottom: "0px solid #333333",
          }}
          xs={12}
        >


          </Grid>

        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "85%",
              lg: "85%",
              md: "85%",
              sm: "85%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{              
              height: "100%",
              width: "100%",
            }}
          >

            {this.getDigitalRelocation()}
            {this.getPrintRelocation()}
            {this.getContactTile()}

          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "5px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Footer theme={this.props.theme}  />
        </Grid>
      </Grid>
    );
  };

  getDigitalRelocation = () => {
    return (
      <React.Fragment>
        <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"15px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={"My Relocation Service"}
            topTxtColor={this.props.theme.bannerTextColor}
            topTxtSize={["1.2rem", "1.2rem", "1.2rem"]}
            topTxtType={"bold"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={this.props.theme.bannerColor}
            topHeight={["50px", "10%", "10%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"5px"}
            mdlPicture={"downloads/relocation3.png"}
            mdlPictureAlign={"50% 50%"}
            mdlPictureSize={"80%"}
            mdlTxt={
              null
        
            }
            mdlTxtColor={this.props.theme.textColor}
            mdlTxtSize={["1rem", ".8rem", ".8rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"rgba(255,255,255,1)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["200px", "50%", "55%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
              "I have helped folks relocating to and from central New York from as far away as Washington, Nevada, Texas and Florida.  If you're looking to move to central New York or are on your way to a new destination let me help make your move smooth and stress free."
            }
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={["1rem", ".8rem", "1rem"]}
            btmTxtType={"bold"}
            btmTxtAlign={"left"}
            btmTxtLineHeight={"1.25"}
            btmOverflow={"hidden"}
            btmHorizontalAlign={"flex-start"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"0px solid black"}
            btmHeight={["150px", "40%", "35%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );


  }

  getPrintRelocation = () => {



    return (
      <React.Fragment>
        {this.state.sellingAHomeTiles.map((tile, index) => {
          return (
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
               
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
               <CommonTile
                animationType={null}
                animationTime={null}
                tagTopLeftTxt={
                  this.state.relocationInProgress ?                     
                  <div  style={{ padding: '0px 20px 0px 20px' }}>
                  <CircularProgress disableShrink size={12} sx={{color:this.props.theme.bannerTextColor, opacity:"1"}}/>
                  </div>
                  : "Download"
                }
                tagTopLeftPadding={"5px 10px 5px 10px"}
                tagTopLeftTxtColor={this.props.theme.bannerTextColor}
                tagTopLeftTxtSize={[".75rem", ".75rem", ".75rem"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"5px"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={this.props.theme.bannerColor}
                tagTopLeftBorder={"0px solid " + this.props.theme.bannerTextColor}
                tagTopLeftBorderRadius={["0px", "20px", "20px", "0px"]}
                tagTopLeftCallback={ () => this.downloadResources(tile.download)}                                

                topProgress={this.state.relocationInProgress}
                topPadding={"0px"}
                topPicture={null}
                topPictureAlign={null}
                topPictureSize={null}
                topTxt={null}
                topTxtColor={"black"}
                topTxtSize={["1.2rem", "1.2rem", "1.2rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={tile.fullSize ? ["50px", "10%", "10%"] : ["250px", "60%", "60%"]}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={tile.image}
                mdlPictureAlign={"50% 10%"}
                mdlPictureSize={"contain"}
                mdlTxt={null}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", "1rem", "1.2rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={tile.fullSize ? "white" : this.props.theme.bannerColor}
                mdlBorder={tile.fullSize ?  "0px solid black" : "1px solid black"}
                mdlHeight={["300px", "80%", "80%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={tile.name}
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["1rem", ".8rem", "1rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"center"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={tile.fullSize ? "0px solid black" : "1px solid black"}
                btmHeight={tile.fullSize ? ["50px", "10%", "10%"] : ["150px", "30%", "30%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
              </Grid>
          );
        })}
      </React.Fragment>
    );


  }

  getContactTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "Let Me Help Facilitate Your Relocation",
      summary:
      "From long distance buying and selling to helping \
      you get set up with our local utilities services or helping you find a great realtor in your new location.  \
      I'm here to provide you a stress free relocation experience.",
      download: true,
    }

    return (
      <React.Fragment>
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
                animationType={null}
                animationTime={null}
                tagPicture={null}
                tagTxt={tile.tag}
                tagTxtColor={"black"}
                tagTxtSize={["12px", "12px", "16px"]}
                tagTxtType={"bold"}
                tagTxtAlign={"center"}
                tagVerticalAlign={"flex-start"}
                tagHorizontalAlign={"flex-end"}
                tagBgColor={"#73be73"}
                tagBorder={"1px solid black"}
                tagBorderRadius={"15px"}
                topPadding={"5px"}
                topPicture={tile.image}
                topPictureAlign={"100% 100%"}
                topPictureSize={"contain"}
                topTxt={null}
                topTxtColor={"black"}
                topTxtSize={["12px", "12px", "12px"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={["225px", "60%", "60%"]}
                topHorizontalAlign={"center"}
                topVerticalAlign={"flex-end"}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={tile.name}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", "1rem", "1.2rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={this.props.theme.bannerColor}
                mdlBorder={"1px solid black"}
                mdlHeight={["50px", "10%", "10%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={tile.summary}
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["1rem", ".8rem", "1rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"left"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"flex-start"}
                btmVerticalAlign={"flex-start"}
                btmBgColor={"white"}
                btmBorder={"1px solid black"}
                btmHeight={["150px", "30%", "30%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
            </Grid>
      </React.Fragment>
    );
  };


  
  render() {

 
    return (
      <Drawer
          anchor="bottom"
          open={this.props.showRelocation}
          onClose={this.props.toggleRelocation}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "88%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",

              backgroundImage: "url('"+ this.props.theme.background +"')",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

              marginLeft: "auto",
              pointerEvents:"auto",
              zINdex:this.props.zindex
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"1px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "5px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                padding: "0px 0px 0px 5px",               
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem",

                },               
                color:this.props.theme.bannerTextColor
              }}
            >
             Relocation
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact("BuyingSelling")}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "5px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                  
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.props.toggleRelocation}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "0px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.MyServicesRelocationSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top MyServicesRelocationage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                null,
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>
      </Grid>
      </Drawer>
    );
  }
}

export default MyServicesRelocation;
