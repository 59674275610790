import Grid from "@mui/material/Grid";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import HelpIcon from '@mui/icons-material/HelpOutline';
import AlertDialog from './AlertDialog'
import Footer from './Footer'
import _ from "lodash";


class Financing extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      mortgagePayment: 0,
      selectedFinancing: 250000,
      selectedPercentage: 5.5,
      selectedTerm:15,
      showContact: false,
      pictureHeight: "100%",
      pictureWidth: "60%",

      alertDialogOpen:false,      

      propertyTaxes: 500,
      pmi: 100,
      insurance: 100,
      monthlyPayment: 100,
      hoa: 0,

      financeInProgress:false
    }

  }

  componentDidMount() {
    // Set state and call a function inline
    this.calculatePayment(this.state.selectedFinancing, this.state.selectedPercentage, this.state.selectedTerm)

  }

  
  closeAlertDialog = () => {

    this.setState({
      alertDialogOpen: false
    })

  }

  sendFinancingInfo = () => {

    this.setState({financeInProgress:true }, () => {
      this.props.getFinanceDownload(
        this.props.parentPage,
        this.state.selectedFinancing,
        this.state.selectedPercentage,
        this.state.selectedTerm,
        this.state.pmi,
        this.state.propertyTaxes,
        this.state.insurance,
        this.state.hoa,
        this.callback)});

  }

  callback = (data) => {

    // Create a URL for the blob object
    const url = window.URL.createObjectURL(data);
      
    // Create a new link element
    const link = document.createElement('a');

    // Set the link's href attribute to the URL
    link.href = url;

    // Set the link's download attribute to the desired file name
    link.download = "financing-report";

    // Click the link to initiate the download
    link.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(url);

    this.setState({financeInProgress:false, requestSent:true, enableSubmit:false})
  }


  handleInputChange = (event) => {
    const { name, value } = event.target;
    
    // Remove non-numeric characters
    const numericInput = value.replace(/[^0-9]/g, '');
    this.setState({ [name]: numericInput });
  };

  calculatePayment = (principal, interestRate, years) => {

    // Calculate monthly interest rate
    const monthlyInterestRate = interestRate / 12 / 100;
  
    // Calculate number of monthly payments
    const numberOfPayments = years * 12;
  
    // Calculate mortgage payment
    const mortgagePayment =
      (principal *
        monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
      (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
  
    this.setState({
      mortgagePayment: Math.floor(mortgagePayment)
    })
  }

  handleFinancingChange = (event, newValue) => {

    this.setState({ selectedFinancing: newValue }, () => {
      this.calculatePayment(this.state.selectedFinancing, this.state.selectedPercentage, this.state.selectedTerm)
    });
  };

  handlePercentageChange = (event, newValue) => {

    this.setState({ selectedPercentage: newValue }, () => {
      this.calculatePayment(this.state.selectedFinancing, this.state.selectedPercentage, this.state.selectedTerm)
    });

  };

  
  handleTermChange = (event, newValue) => {

    this.setState({ selectedTerm: newValue }, () => {
      this.calculatePayment(this.state.selectedFinancing, this.state.selectedPercentage, this.state.selectedTerm)
    });

  };

  handleChange = (event, newValue) => {
    this.setState({ selectedTerm: newValue });
  };

  formatDollarValueLabel = (value) => {
    // Format the value as desired
    return "$"+value.toLocaleString();
  };

  formatPercentValueLabel = (value) => {
    // Format the value as desired
    return (Number(value.toFixed(2)) + "%");
  };

  formatTermValueLabel = (value) => {
    // Format the value as desired
    return (Number(value.toFixed(0)) + " yrs");
  };

  getFinancingTile = () => {

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            width: "100%",
            padding: {
              xs:"10px",
              sm:"10px",
              md:"10px",
              lg:"20px",
              xl:"20px"
            },
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
            },
          }}
          xl={4}
          lg={4}
          md={4}
          sm={6}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"center"}
            tagHorizontalAlign={"center"}
            tagBgColor={"#73be73"}
            tagBorder={"1px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"10px 50px 15px 50px"}
            topPicture={null}
            topWidget={
              <React.Fragment>
               <Slider
                marks={true}
                min={50000}
                max={950000}
                step={25000}
                valueLabelDisplay="on"
                getAriaLabel={() => 'Prices'}
                value={this.state.selectedFinancing}
                sx={{
                  '& .MuiSlider-valueLabel': {
                    fontSize: 12, // Set the font size here
                    background:this.props.theme.textColor
                  },
                  color:this.props.theme.textColor

                }}
                valueLabelFormat={this.formatDollarValueLabel}
                onChange={this.handleFinancingChange}
              />   
              <br></br>
              <Slider
              marks={true}
              min={1}
              max={9.5}
              step={.25}
              valueLabelDisplay="on"
              getAriaLabel={() => 'Percentage'}
              value={this.state.selectedPercentage}
              sx={{
                '& .MuiSlider-valueLabel': {
                  fontSize: 12, // Set the font size here
                  background:this.props.theme.textColor
                },
                color:this.props.theme.textColor
              }}
              valueLabelFormat={this.formatPercentValueLabel}
              onChange={this.handlePercentageChange}
            />   
            <br></br>
              <Slider
              marks={true}
              min={10}
              max={30}
              step={5}
              valueLabelDisplay="on"
              getAriaLabel={() => 'Term'}
              value={this.state.selectedTerm}
              sx={{
                '& .MuiSlider-valueLabel': {
                  fontSize: 12, // Set the font size here
                  background:this.props.theme.textColor
                },
                color:this.props.theme.textColor

              }}
              valueLabelFormat={this.formatTermValueLabel}
              onChange={this.handleTermChange}
            />   
           
            </React.Fragment>
            }
            topPictureAlign={null}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "1rem", "1rem"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"flex-end"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["225px", "60%", "60%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={"Your Mortgage Payment"}
            mdlWidget={null}
            mdlCallback={null}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["16px", "1rem", "1.2rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"1px solid black"}
            mdlHeight={["50px", "10%", "10%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={ <div>If you borrow <b>${this.state.selectedFinancing.toLocaleString()}</b> at <b>{this.state.selectedPercentage}%</b> over <b>{this.state.selectedTerm} years</b> your monthly mortgage payment will be approximately <b>*${this.state.mortgagePayment.toLocaleString()}</b>.<br></br><br></br><div style={{"fontSize": "12px"}}><i>* Estimated payment</i></div></div>}
            btmCallback={null}
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={["1rem", ".8rem", "1rem"]}
            btmTxtLineHeight={"1.25"}
            btmTxtType={"bold"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"rgba(255, 255, 255, 0)"}
            btmBorder={"1px solid black"}
            btmHeight={["150px", "30%", "30%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  formatOptionValue = (value) => {
    return `$ ${value}`;
  };

  getContactFinanceTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "Request A Showing",
      summary:
      "Howard Hanna offers a real estate experience that is truly unique. Let us walk you through the entire home buying or selling process from start to finish with the help of our exclusive marketing and loan programs.",
      download: true,
    }

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            width: "100%",
            padding: {
              xs:"10px",
              sm:"10px",
              md:"10px",
              lg:"20px",
              xl:"20px"
            },
            height: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
            },
          }}
          xl={4}
          lg={4}
          md={4}
          sm={6}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTopLeftIcon={null}
            tagTopLeftTxt={null}
            tagTopLeftPadding={"5px 10px 5px 10px"}
            tagTopLeftTxtColor={"black"}
            tagTopLeftTxtSize={null}
            tagTopLeftPaddingLeft={"0px"}
            tagTopLeftPaddingTop={"0%"}
            tagTopLeftTxtType={"bold"}
            tagTopLeftTxtAlign={"center"}
            tagTopLeftBgColor={null}
            tagTopLeftBorder={"0px solid black"}
            tagTopLeftBorderRadius={"0px"}
            topPadding={"15px 25px 10px 25px"}
            topPicture={null}
            topPictureAlign={"50% 70%"}
            topPictureSize={"contain"}
            topTxt={
              <Grid container sx={{widht:"100%", height:"100%", padding:"0px"}} >
              <Grid item sx={{fontSize:"1rem", padding:"10px 0px 10px 0px", textAlign:"left"}} xs={6}>Mortgage Payment</Grid>
              <Grid item sx={{fontSize:"1.25rem", padding:"10px 0px 10px 10px", textAlign:"left"}} xs={5}>
                ${this.state.mortgagePayment.toLocaleString()}

              </Grid>
              <Grid item sx={{padding:"0px 0px 0px 10px"}} xs={1}>
                
                  
                </Grid>
              <Grid item sx={{fontSize:"1rem", paddingTop:"10px", textAlign:"left"}} xs={6}>Property Taxes</Grid>
              <Grid item xs={5}>
              <Select
                size="small"
                value={this.state.propertyTaxes}
                onChange={this.handleInputChange}
                displayEmpty
                fullWidth
                
                name="propertyTaxes"
                renderValue={(value) => this.formatOptionValue(value)}
                sx={{                
                  textAlign:"left",
                  backgroundColor:"white",
                  border:{
                    xs:"0px solid #AAAAAA",
                    sm:"0px solid #AAAAAA",
                    md:"1px solid #AAAAAA"
                  },
                  fontSize:{
                    xs:"1rem", 
                    sm:"1rem", 
                    md:"1rem", 
                    lg:"1rem", 
                    xl:"1rem"
                  },
                  padding:{
                    xs:"2px", 
                    sm:"2px", 
                    md:"0px", 
                    lg:"0px", 
                    xl:"0px"
                  },
                  float:"left",
                  "&:hover": {
                    backgroundColor: 'white',
                  },
                  borderRadius:"0px"
                }}
                label="Taxes"
              >
                <MenuItem value="100">100</MenuItem>
                <MenuItem value="200">200</MenuItem>
                <MenuItem value="300">300</MenuItem>
                <MenuItem value="400">400</MenuItem>
                <MenuItem value="500">500</MenuItem>
                <MenuItem value="600">600</MenuItem>
                <MenuItem value="700">700</MenuItem>
                <MenuItem value="800">800</MenuItem>
                <MenuItem value="900">900</MenuItem>
                <MenuItem value="1000">1000</MenuItem>
                <MenuItem value="1100">1100</MenuItem>
                <MenuItem value="1200">1200</MenuItem>
                <MenuItem value="1300">1300</MenuItem>
                <MenuItem value="1400">1400</MenuItem>
                <MenuItem value="1500">1500</MenuItem>
                <MenuItem value="1600">1600</MenuItem>
                <MenuItem value="1700">1700</MenuItem>
                <MenuItem value="1800">1800</MenuItem>
                <MenuItem value="1900">1900</MenuItem>
                <MenuItem value="2000">2000</MenuItem>
              </Select>
                
              </Grid>
              <Grid item sx={{padding:"10px 0px 0px 10px", cursor:"pointer"}} xs={1}> 
              <HelpIcon onClick={() => { this.setState({ alertDialogTitle: "Estimating Property Taxes", alertDialogMessage: "Property tax costs vary, A general rule of thumb is to use 0.3% of the appraised vaule of your home to calculate your year property taxes. Your montly cost would be 1/12th of the calculated value. For example, property taxes on a $400,000 home would be about $12,000 per year, which is $1000 per month.", alertDialogOpen:true})}}/> 
             </Grid>

              <Grid item sx={{fontSize:"1rem", paddingTop:"10px", textAlign:"left"}} xs={6}>Mortgage Insurance(PMI)</Grid>
              <Grid item xs={5}>
              <Select
                size="small"
                value={this.state.pmi}
                onChange={this.handleInputChange}
                displayEmpty
                fullWidth
                
                name="pmi"
                renderValue={(value) => this.formatOptionValue(value)}
                sx={{
                  textAlign:"left",
                  backgroundColor:"white",
                  border:{
                    xs:"0px solid #AAAAAA",
                    sm:"0px solid #AAAAAA",
                    md:"1px solid #AAAAAA"
                  },
                  fontSize:{
                    xs:"1rem", 
                    sm:"1rem", 
                    md:"1rem", 
                    lg:"1rem", 
                    xl:"1rem"
                  },
                  padding:{
                    xs:"2px", 
                    sm:"2px", 
                    md:"0px", 
                    lg:"0px", 
                    xl:"0px"
                  },
                  float:"left",
                  "&:hover": {
                    backgroundColor: 'white',
                  },
                  borderRadius:"0px"
                }}
                label="Taxes"
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="75">75</MenuItem>
                <MenuItem value="100">100</MenuItem>
                <MenuItem value="125">125</MenuItem>
                <MenuItem value="150">150</MenuItem>
                <MenuItem value="175">175</MenuItem>
                <MenuItem value="200">200</MenuItem>
                <MenuItem value="225">225</MenuItem>
                <MenuItem value="250">250</MenuItem>
              </Select>
                
              </Grid>
              <Grid item sx={{padding:"10px 0px 0px 10px", cursor:"pointer"}} xs={1}> 
              <HelpIcon onClick={() => { this.setState({ alertDialogTitle: "Estimating Mortgage Insurance", alertDialogMessage: "Primary Mortgage Insurance (PMI) is typically paid when you have less than 20% ownership of the residence.  PMI cost can be about 1% of the mortgage vaule per year. Your montly cost would be 1/12th of one percent of your mortgage. For example, PMI on a mortgage of $120,000 would be about $1,200 per year, or $100 per month. ", alertDialogOpen:true})}}/> 
             </Grid>
              <Grid item sx={{fontSize:"1rem", paddingTop:"10px", textAlign:"left"}} xs={6}>Home Owners Insurance</Grid>
              <Grid item xs={5}>
              <Select
                size="small"
                value={this.state.insurance}
                onChange={this.handleInputChange}
                displayEmpty
                fullWidth
                
                name="insurance"
                renderValue={(value) => this.formatOptionValue(value)}
                sx={{
                  textAlign:"left",
                  backgroundColor:"white",
                  border:{
                    xs:"0px solid #AAAAAA",
                    sm:"0px solid #AAAAAA",
                    md:"1px solid #AAAAAA"
                  },
                  fontSize:{
                    xs:"1rem", 
                    sm:"1rem", 
                    md:"1rem", 
                    lg:"1rem", 
                    xl:"1rem"
                  },
                  padding:{
                    xs:"2px", 
                    sm:"2px", 
                    md:"0px", 
                    lg:"0px", 
                    xl:"0px"
                  },
                  float:"left",
                  "&:hover": {
                    backgroundColor: 'white',
                  },
                  borderRadius:"0px"
                }}
                label="Taxes"
              >
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="75">75</MenuItem>
                <MenuItem value="100">100</MenuItem>
                <MenuItem value="125">125</MenuItem>
                <MenuItem value="150">150</MenuItem>
                <MenuItem value="175">175</MenuItem>
                <MenuItem value="200">200</MenuItem>
                <MenuItem value="225">225</MenuItem>
                <MenuItem value="250">250</MenuItem>
                <MenuItem value="275">275</MenuItem>
                <MenuItem value="300">300</MenuItem>
              </Select>
                
              </Grid>
              
              <Grid item sx={{padding:"10px 0px 0px 10px", cursor:"pointer"}} xs={1}> 
              <HelpIcon onClick={() => { this.setState({ alertDialogTitle: "Home Owners Insurance", alertDialogMessage: "Home owners insurance generally about $300 per year ($25/Mo) for each $50,000 of value you're insuring. For example if your insuring $250,000 your yearly cost could be about $1500/Yr or about $125 per month", alertDialogOpen:true})}}/> 
             </Grid>
             <Grid item sx={{fontSize:"1rem", paddingTop:"10px", textAlign:"left"}} xs={6}>HOA/Monthly</Grid>
              <Grid item xs={5}>
              <Select
                size="small"
                value={this.state.hoa}
                onChange={this.handleInputChange}
                displayEmpty
                fullWidth
                
                name="hoa"
                renderValue={(value) => this.formatOptionValue(value)}
                sx={{
                  textAlign:"left",
                  backgroundColor:"white",
                  border:{
                    xs:"0px solid #AAAAAA",
                    sm:"0px solid #AAAAAA",
                    md:"1px solid #AAAAAA"
                  },
                  fontSize:{
                    xs:"1rem", 
                    sm:"1rem", 
                    md:"1rem", 
                    lg:"1rem", 
                    xl:"1rem"
                  },
                  padding:{
                    xs:"2px", 
                    sm:"2px", 
                    md:"0px", 
                    lg:"0px", 
                    xl:"0px"
                  },
                  float:"left",
                  "&:hover": {
                    backgroundColor: 'white',
                  },
                  borderRadius:"0px"
                }}
                label="Taxes"
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="75">75</MenuItem>
                <MenuItem value="100">100</MenuItem>
                <MenuItem value="125">125</MenuItem>
                <MenuItem value="150">150</MenuItem>
                <MenuItem value="175">175</MenuItem>
                <MenuItem value="200">200</MenuItem>
                <MenuItem value="225">225</MenuItem>
                <MenuItem value="250">250</MenuItem>
                <MenuItem value="275">275</MenuItem>
                <MenuItem value="300">300</MenuItem>
              </Select>
                
              </Grid>
              
              <Grid item sx={{padding:"10px 0px 0px 10px", cursor:"pointer"}} xs={1}> 
              <HelpIcon onClick={() => { this.setState({ alertDialogTitle: "Home Owners Association", alertDialogMessage: "Home Owners Association (HOA) fees can vary greatly from community to community and often are dependant on the services the HOA provides and the overall size or value of your home in the community.  The homes listing should provide details on HOA costs.", alertDialogOpen:true})}}/> 
             </Grid>

            </Grid>
            }
            topTxtColor={this.props.theme.textColor}
            topTxtSize={["1rem", "1rem", "1rem"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"flex-end"}
            topVerticalAlign={"flex-end"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["225px", "60%", "60%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"5px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={"Total Monthly Payment"}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["1rem", "1rem", "1.2rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"left"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"1px solid black"}
            mdlHeight={["50px", "10%", "10%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
              <Grid container alignItems="center" justifyContent="center" sx={{width:"100%", height:"100%", padding:"0px"}} >                
                <div>
                  <div style={{fontSize:"2.5rem"}}><b>* ${(parseInt(this.state.mortgagePayment) + parseInt(this.state.propertyTaxes) + parseInt(this.state.pmi) + parseInt(this.state.insurance) + parseInt(this.state.hoa)).toLocaleString()}</b></div>
                  <div style={{"fontSize": "12px"}}><i>* Estimated payment.</i></div>
                </div>
            </Grid>

            }
            btmCallback={null}
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={["15px", ".75rem", "1rem"]}
            btmTxtType={"normal"}
            btmTxtLineHeight={"1.5"}
            btmTxtAlign={"left"}
            btmHorizontalAlign={"flex-start"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"rgba(255, 255, 255, 1)"}
            btmBorder={"1px solid black"}
            btmHeight={["150px", "30%", "30%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  toggleHomeValue = () => {
    this.setState({
      HomeValue: !this.state.HomeValue,
    });
  };
  
  getContactTile = () => {
    let tile = {
      id: "1",
      image: "downloads/my-services-financing-report.png",
      tag: null,
      name: "Download Your Payment Report",
      summary:
      "Howard Hanna offers a real estate experience that is truly unique. Let us walk you through the entire home buying or selling process from start to finish with the help of our exclusive marketing and loan programs.",
      download: true,
    }

    return (
      <React.Fragment>
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
                animationType={null}
                animationTime={null}
                tagPicture={null}
                tagTopLeftTxt={
                  this.state.financeInProgress ?                     
                  <div  style={{ padding: '0px 20px 0px 20px' }}>
                  <CircularProgress disableShrink size={12} sx={{color:this.props.theme.bannerTextColor, opacity:"1"}}/>
                  </div>
                  : "Download"
                }
                tagTopLeftPadding={"5px 10px 5px 10px"}
                tagTopLeftTxtColor={this.props.theme.bannerTextColor}
                tagTopLeftTxtSize={[".75rem", ".75rem", ".75rem"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"5px"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={this.props.theme.bannerColor}
                tagTopLeftBorder={"0px solid " + this.props.theme.bannerTextColor}
                tagTopLeftBorderRadius={["0px", "20px", "20px", "0px"]}
                tagTopLeftCallback={!this.state.financeInProgress ? this.sendFinancingInfo : null}                                

                topPadding={"5px"}
                topPicture={null}
                topPictureAlign={null}
                topPictureSize={null}
                topTxt={null}
                topTxtColor={"black"}
                topTxtSize={["1rem", "1rem", "1rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={["50px", "10%", "10%"]}
                topHorizontalAlign={"center"}
                topVerticalAlign={"center"}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={tile.image}
                mdlPictureAlign={"50% 0%"}
                mdlPictureSize={"contain"}
                mdlTxt={null}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", "1rem", "1.2rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={this.props.theme.bannerColor}
                mdlBorder={"0px solid black"}
                mdlHeight={["300px", "80%", "80%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                mdlProgress={null}
                mdlProgressSize={40}
                mdlProgressColor={null}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={tile.name}
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["1rem", ".8rem", "1rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"left"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["50px", "10%", "10%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}

              />
            </Grid>
      </React.Fragment>
    );
  };


  downloadResources = () => {
    // Make a request to the server to download the file
    fetch("/downloads/financing-report.png")
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = "downloads/financing-report.png";
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }

  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,.75)",
          height: "100%",
          width: "100%",
          borderBottom: "1px solid #333333",
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "5%",
              lg: "5%",
              md: "5%",
              sm: "5%",
            },
            padding: "10px",
            fontSize: "2rem",
          }}
          xs={12}
        >
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "80%",
              lg: "80%",
              md: "80%",
              sm: "80%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            {this.getFinancingTile()}
            {this.getContactFinanceTile()}
            {this.getContactTile()}

          </Grid>


        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "5%",
              lg: "5%",
              md: "5%",
              sm: "5%",
            },
            padding: "0px 20px 0px 20px",
            fontSize: {
              md: ".6rem",
              lg: ".7rem",
              xl: ".7rem"
            },
            color:"#888888"
          }}
          xs={12}
        >

        * This calculator is intended solely for general information and educational purposes.  There is no guaranty of the accuracy of the calculations or the availability of any of the terms provided. Your actual rate, payment, and costs may differ. Get an official Loan Estimate before choosing a loan. The calculator is not intended in any way as financial, insurance, tax or legal information regarding your financial situation, please consult with a financial advisor.
        </Grid>

        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "5px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Footer theme={this.props.theme} />
        </Grid>
      </Grid>
    );
  };




  render() {

 
    return (
      <Drawer
          anchor="bottom"
          open={this.props.showFinancing}
          onClose={this.props.toggleFinancing}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "88%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",

              backgroundImage: "url('"+ this.props.theme.background +"')",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

              marginLeft: "auto",
              pointerEvents:"auto"
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"1px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              textAlign: "left",
              padding: "5px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                padding: "0px 0px 0px 5px",               
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem"
                },
                color:this.props.theme.bannerTextColor
                      
              }}
            >              
              Payment Calculator


            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact("MortgageFinance")}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "5px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.props.toggleFinancing}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
                  marginRight: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "0px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  }
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.financingSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top HomeValueage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                null,
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>
      </Grid>
      <AlertDialog theme={this.props.theme} open={this.state.alertDialogOpen} yesButtonLabel="Ok" yesButtonAction={this.closeAlertDialog} title={this.state.alertDialogTitle} message={this.state.alertDialogMessage} />
      </Drawer>
    );
  }
}

export default Financing;
