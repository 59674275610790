import Grid from "@mui/material/Grid";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from "./Footer";
import Drawer from "@mui/material/Drawer";
import SlideShowDialog from "./SlideShowDialog";
import VideoShowDialog from "./VideoShowDialog";
import CircularProgress from '@mui/material/CircularProgress';

import _ from "lodash";

// Top Marketing
// Strong Negoitaors
// Experiaced Team
// Stagging & Photography
// Resources
// Contact Your Realtor

class MyServicesMarketing extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      marketingInProgress:false,
      showSlideShowDialog: false,
      showVideoShowDialog: false,
      sellingAHomeTiles: [
        {
          id: "1",
          image: "downloads/my-services-marketing.png",
          name: "I'll Have A Great Marketing Plan",
          fullSize: true,
          summary:
            "My top priority is to provide exceptional service that exceeds your home buying or selling expectations. You can trust that I will guide you through each step of the process with confidence and expertise.  Click here to schedule an appointment.",
          download: "marketing",
        },
      ],

      selectedEntry:  0,
      pictureHeight: "100%",
      pictureWidth: "60%",
    }
  }

  toggleSlideShowDialog = () => {
    this.setState({
      showSlideShowDialog: !this.state.showSlideShowDialog,
      showVideoShowDialog: false
    })
  }
 

  toggleMyServicesMarketing = () => {
    this.setState({
      MyServicesMarketing: !this.state.MyServicesMarketing,
      showSlideShowDialog: false,
      showVideoShowDialog: false
    });
  };

  toggleVideoShowDialog = () => {
    this.setState({
      showSlideShowDialog: false,
      showVideoShowDialog: !this.state.showVideoShowDialog
    })
  }

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };

  toggleRequestForm = (listing) => {
    this.setState({
      showListing: this.props.listings[0],
      contactForm: !this.state.contactForm,
      contactReason: "Request A Showing",
    });
  };



  downloadResources = (filename) => {

    this.setState({marketingInProgress:true}, () => this.props.getInformationDownload("MyServices", filename, this.callback))
  }

  callback = (response) => {

    if(response.data.message){
      const base64String = response.data.message;

      // Convert base64 string to Blob
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });

      // Create a download link and trigger click
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = response.data.filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

    }
    this.setState({marketingInProgress:false})

  }

  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,.75)",
          height: "100%",
          width: "100%",
          borderBottom: "1px solid #333333",
        }}
      >
                <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "5%",
              lg: "5%",
              md: "5%",
              sm: "5%",
            },
            padding: "5px",
            fontSize: "2rem",
            borderBottom: "0px solid #333333",
          }}
          xs={12}
        >


          </Grid>

        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "85%",
              lg: "85%",
              md: "85%",
              sm: "85%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{              
              height: "100%",
              width: "100%",
            }}
          >

            {this.getDigitalMarketing()}
            {this.getPhotographyVideoMarketing()}
            {this.getPrintMarketing()}

          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "5px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Footer theme={this.props.theme}  />
        </Grid>
      </Grid>
    );
  };

  getDigitalMarketing = () => {
    return (
      <React.Fragment>
        <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"15px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={"Digital & Print Marketing"}
            topTxtColor={this.props.theme.bannerTextColor}
            topTxtSize={["1.2rem", "1rem", "1.2rem"]}
            topTxtType={"bold"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={this.props.theme.bannerColor}
            topHeight={["50px", "10%", "10%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"5px"}
            mdlPicture={"downloads/marketing2.png"}
            mdlPictureAlign={"50% 20%"}
            mdlPictureSize={"contain"}
            mdlTxt={
              null
            }
            mdlTxtColor={this.props.theme.textColor}
            mdlTxtSize={["1rem", ".8rem", ".8rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"rgba(255,255,255,1)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["200px", "55%", "60%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
              "I'll implement a marketing strategy you approve. My marketing service is included when you list with me and I'll create and facilitate all the print and digital marketing for your home."
            }
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={["1rem", ".8rem", "1rem"]}
            btmTxtType={"bold"}
            btmTxtAlign={"left"}
            btmTxtLineHeight={"1.25"}
            btmOverflow={"hidden"}
            btmHorizontalAlign={"flex-start"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"0px solid black"}
            btmHeight={["150px", "35%", "30%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );


  }


  getPhotographyVideoMarketing = () => {
    return (
      <React.Fragment>
        <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"15px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={"Photography & Video"}
            topTxtColor={this.props.theme.bannerTextColor}
            topTxtSize={["1.2rem", "1rem", "1.2rem"]}
            topTxtType={"bold"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={this.props.theme.bannerColor}
            topHeight={["50px", "10%", "10%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"5px"}
            mdlPicture={"downloads/photographyvideo.png"}
            mdlPictureAlign={"50% 20%"}
            mdlPictureSize={"contain"}
            mdlTxt={null
            }
            mdlTxtColor={this.props.theme.textColor}
            mdlTxtSize={["1rem", ".8rem", ".8rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"rgba(255,255,255,1)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["200px", "55%", "60%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
              <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    
                    height: {
                      xs: "100%",
                    },
                    width: {
                      xs: "100%",
                    },
                    
                    borderTop:{
                      sm: "0px solid #333333"
                    }
                  }}
                >

                <Grid
                  item
                  sx={{
                    padding: "0px",
                    height: {
                      xl: "75%",
                      lg: "75%",
                      md: "75%",
                      sm: "75%",
                    },
                    overflowY: "auto",
                    overflowX: "hidden",
                    border: "0px solid red",
                    
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  My photography and video services are also included.  I'll make sure your home has a premium presentation on my website, across my social media feeds, and at my open houses.
                  </Grid>
                  <Grid
                  item
                  sx={{
                    padding: "0px",
                    background:this.props.theme.bannerColor,
                    color:this.props.theme.bannerTextColor,
                    height: {
                      xl: "25%",
                      lg: "25%",
                      md: "25%",
                      sm: "25%",
                    },
                    overflowY: "hidden",
                    overflowX: "hidden",
                    fontSize: {
                      "md": ".8rem",
                      "lg": ".8rem"
                    },
                    border: "0px solid red",
                    textAlign:"center",   
                    borderRight:"5px solid " + this.props.theme.bannerTextColor ,    
                    borderTopLeftRadius: "25px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "25px",            
                    cursor:"pointer"
                  }}
                  onClick = { () => this.toggleSlideShowDialog() }
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        overflowY:"hidden",             
                        height: "100%",
                        width: { md: "100%", xs: "100%" },
                      }}
                    >

                    See My Photography
                    </Grid>
                  </Grid>
                  <Grid
                  item
                  sx={{
                    padding: "2px",
                    background:this.props.theme.bannerColor,
                    color:this.props.theme.bannerTextColor,
                    height: {
                      xl: "25%",
                      lg: "25%",
                      md: "25%",
                      sm: "25%",
                    },
                    overflowY: "hidden",
                    overflowX: "hidden",
                    fontSize: {
                      "md": ".8rem",
                      "lg": ".8rem"
                    },
                    border: "0px solid red",
                    textAlign:"center",       
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "25px",
                    borderBottomRightRadius: "25px",
                    borderBottomLeftRadius: "0px",                         
                    cursor:"pointer"
                  }}
                  onClick = { () => this.toggleVideoShowDialog() }
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        overflowY:"hidden",             
                        height: "100%",
                        width: { md: "100%", xs: "100%" },
                      }}
                    >
                    One Of My Videos 
                  </Grid>
                  </Grid>

              </Grid>
            }
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={["1rem", ".8rem", "1rem"]}
            btmTxtType={"bold"}
            btmTxtAlign={"left"}
            btmTxtLineHeight={"1.25"}
            btmOverflow={"hidden"}
            btmHorizontalAlign={"flex-start"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"0px solid black"}
            btmHeight={["150px", "35%", "30%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );


  }


  getPrintMarketing = () => {



    return (
      <React.Fragment>
        {this.state.sellingAHomeTiles.map((tile, index) => {
          return (
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
               
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
               <CommonTile
                animationType={null}
                animationTime={null}
                tagTopLeftTxt={
                  this.state.marketingInProgress ?                     
                  <div  style={{ padding: '0px 20px 0px 20px' }}>
                  <CircularProgress disableShrink size={12} sx={{color:this.props.theme.bannerTextColor, opacity:"1"}}/>
                  </div>
                  : "Download"
                }
                tagTopLeftPadding={"5px 10px 5px 10px"}
                tagTopLeftTxtColor={this.props.theme.bannerTextColor}
                tagTopLeftTxtSize={[".75rem", ".75rem", ".75rem"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"5px"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={this.props.theme.bannerColor}
                tagTopLeftBorder={"0px solid " + this.props.theme.bannerTextColor}
                tagTopLeftBorderRadius={["0px", "20px", "20px", "0px"]}
                tagTopLeftCallback={ () => this.downloadResources(tile.download)}                                

                topProgress={null}
                topPadding={"0px"}
                topPicture={null}
                topPictureAlign={null}
                topPictureSize={null}
                topTxt={null}
                topTxtColor={"black"}
                topTxtSize={["1rem", "1rem", "1rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={tile.fullSize ? ["50px", "10%", "10%"] : ["250px", "60%", "60%"]}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={tile.image}
                mdlPictureAlign={"50% 10%"}
                mdlPictureSize={"contain"}
                mdlTxt={null}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", "1rem", "1.2rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={tile.fullSize ? "white" : this.props.theme.bannerColor}
                mdlBorder={tile.fullSize ?  "0px solid black" : "1px solid black"}
                mdlHeight={["300px", "80%", "80%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={tile.name}
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["1rem", ".8rem", "1rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"center"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={tile.fullSize ? "0px solid black" : "1px solid black"}
                btmHeight={tile.fullSize ? ["50px", "10%", "10%"] : ["150px", "30%", "30%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
              </Grid>
          );
        })}
      </React.Fragment>
    );


  }

  getContactTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "I'll Be With You Every Step Of The Way",
      summary:
      "My top priority is to provide an exceptional marketing service that exceeds your home selling expectations. You can trust that I will guide you through each step of the process with confidence and expertise.",
      download: true,
    }

    return (
      <React.Fragment>
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
                animationType={null}
                animationTime={null}
                tagPicture={null}
                tagTxt={tile.tag}
                tagTxtColor={"black"}
                tagTxtSize={["12px", "12px", "16px"]}
                tagTxtType={"bold"}
                tagTxtAlign={"center"}
                tagVerticalAlign={"flex-start"}
                tagHorizontalAlign={"flex-end"}
                tagBgColor={"#73be73"}
                tagBorder={"1px solid black"}
                tagBorderRadius={"15px"}
                topPadding={"5px"}
                topPicture={tile.image}
                topPictureAlign={"100% 100%"}
                topPictureSize={"contain"}
                topTxt={null}
                topTxtColor={"black"}
                topTxtSize={["12px", "12px", "12px"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={["225px", "60%", "60%"]}
                topHorizontalAlign={"center"}
                topVerticalAlign={"flex-end"}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={tile.name}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", "1rem", "1.2rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={this.props.theme.bannerColor}
                mdlBorder={"1px solid black"}
                mdlHeight={["50px", "10%", "10%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={tile.summary}
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["1rem", ".8rem", "1rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"left"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"flex-start"}
                btmVerticalAlign={"flex-start"}
                btmBgColor={"white"}
                btmBorder={"1px solid black"}
                btmHeight={["150px", "30%", "30%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
            </Grid>
      </React.Fragment>
    );
  };


  
  render() {

 
    return (
      <Drawer
          anchor="bottom"
          open={this.props.showMarketing}
          onClose={this.props.toggleMarketing}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "78%",
                sm: "77%",
                md: "73%",
                lg: "74%",
                xl: "74%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              marginLeft: "auto",
              pointerEvents:"auto",
              zINdex:this.props.zindex,
              borderTop:"2px solid " + this.props.theme.bannerColor,

              backgroundImage: "url('"+ this.props.theme.background +"')",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"1px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

            <Grid
              item
              sx={{
                padding: "0px",
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.MyServicesMarketingSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top MyServicesMarketingage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                null,
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>
      </Grid>
      <SlideShowDialog 
        open={this.state.showSlideShowDialog}
        onClose={this.toggleSlideShowDialog}
        listing={{
          "Narrative1": "I'll Create Your Home's Photography Story,",
          "Narrative2": "With Staging Help, Great Lighting & A Premium Presentation",
          "Narrative3": "Your Listing Will Look Amazing!",
        }}
        photos={[
          {
            "MediaURL":"photography/commane-1.jpg"
          },
          {
            "MediaURL":"photography/foxbury-1.jpg" 
          },
          {
            "MediaURL":"photography/foxbury-2.jpg"
          },
          {
            "MediaURL":"photography/norcross-1.jpg"
          },
          {
            "MediaURL":"photography/norcross-2.jpg"
          },
          {
            "MediaURL":"photography/norcross-3.jpg"
          },
          {
            "MediaURL":"photography/gabion-1.jpg"
          },
          {
            "MediaURL":"photography/gabion-2.jpg"
          },
          {
            "MediaURL":"photography/gabion-3.jpg"
          },
          {
            "MediaURL":"photography/gabion-5.jpg"
          },
          {
            "MediaURL":"photography/gabion-6.jpg"
          }
        ]}
        
        doNext={true}
        doPrevious={true}
        doCounter={true}
        mapImage={this.mapImage}
        theme={this.props.theme}
      />
      <VideoShowDialog 
        open={this.state.showVideoShowDialog}
        onClose={this.toggleVideoShowDialog}
        videos={["videos/685idlewood.mp4"]}
        doNext={false}
        doPrevious={false}
        doCounter={false}
        theme={this.props.theme}
      />
      </Drawer>
    );
  }
}

export default MyServicesMarketing;
