
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Image from 'mui-image'
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from "./Footer";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import FeaturedListingsCommunities from './FeaturedListingsCommunities';
import FeaturedListingsRecent from './FeaturedListingsRecent';
import FeaturedListingsLand from './FeaturedListingsLand';
import FeaturedListingsMultifamily from './FeaturedListingsMultifamily';
import FeaturedListingsLuxery from './FeaturedListingsLuxery';
import FeaturedListingsWaterfront from './FeaturedListingsWaterfront';
import FeaturedListingsNewConstruction from './FeaturedListingsNewConstruction';
import ListingReport from "./ListingReport";
import PlayIcon from '@mui/icons-material/PlayCircle';

import _ from "lodash";

// Top Marketing
// Strong Negoitaors
// Experiaced Team
// Stagging & Photography
// Resources
// Contact Your Realtor

class FeaturedListings extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      pictureHeight: "100%",
      pictureWidth: "60%",

      showListingReport: false,

      showCommunities: false,
      showLuxery: false,
      showLand: false,
      showMultifamily: false,
      showWaterfront: false,
      showNewConstruction: false,
      showRecent: false,
      showOlderListings: false,
      showForeclosureListings: false,
      
      listings: [],
      featuredInProgress:false,
      newConstructionListings: [],
      newConstructionTimestamp: 0,
      newConstructionInProgress: false,

      landListings: [],
      landTimestamp: 0,
      landInProgress: false,

      recentListings: [],
      recentTimestamp: 0,
      recentInProgress: false,

      waterfrontListings: [],
      waterfrontTimestamp: 0,
      waterfrontInProgress: false,

      luxeryListings: [],
      luxeryTimestamp: 0,
      luxeryInProgress: false,


      subService: "Featured Listings",
            
    }
  }




  componentDidUpdate(prevProps) {

    if (prevProps.showFeaturedListings !== this.props.showFeaturedListings) {
      this.setState({
        showCommunities: false,
        showLuxery: false,
        showLand: false,
        showMultifamily: false,
        showWaterfront: false,
        showNewConstruction: false,
        showRecent: false,
        showOlderListings: false,
        showForeclosureListings: false,
        subService:"Featured Listings"  
      })
    }

    if(this.props.requestid !== prevProps.requestid){
      this.setState({
        featuredInprogress: true
      }, () => this.props.getFeaturedListings("Featured", "agent", this.callback))
    }
  }

  callback = (response) => {
    if(response && response.data.listings){
      this.setState({
        listings: response.data.listings,
        featuredInProgress:false
      })
    }
  }

  newConstructionCallback = (response) => {
    const millisecondsSinceEpoch = Date.now();

    // Convert milliseconds to seconds by dividing by 1000
    const secondsSinceEpoch = Math.floor(millisecondsSinceEpoch / 1000);

    if(response && response.data && response.data.listings){
      this.setState({
        newConstructionListings: response.data.listings,
        newConstructionTimestamp: secondsSinceEpoch,
        newConstructionInProgress:false
      })
    }
  }


  landCallback = (response) => {
    const millisecondsSinceEpoch = Date.now();

    // Convert milliseconds to seconds by dividing by 1000
    const secondsSinceEpoch = Math.floor(millisecondsSinceEpoch / 1000);

    if(response && response.data && response.data.listings){
      this.setState({
        landListings: response.data.listings,
        landTimestamp: secondsSinceEpoch,
        landInProgress:false
      })
    }
  }


  recentCallback = (response) => {
    const millisecondsSinceEpoch = Date.now();

    // Convert milliseconds to seconds by dividing by 1000
    const secondsSinceEpoch = Math.floor(millisecondsSinceEpoch / 1000);

    if(response && response.data && response.data.listings){
      this.setState({
        recentListings: response.data.listings,
        recentTimestamp: secondsSinceEpoch,
        recentInProgress:false
      })
    }
  }

  waterfrontCallback = (response) => {
    const millisecondsSinceEpoch = Date.now();

    // Convert milliseconds to seconds by dividing by 1000
    const secondsSinceEpoch = Math.floor(millisecondsSinceEpoch / 1000);

    if(response && response.data && response.data.listings){
      this.setState({
        waterfrontListings: response.data.listings,
        waterfrontTimestamp: secondsSinceEpoch,
        waterfrontInProgress:false
      })
    }
  }

  multifamilyCallback = (response) => {
    const millisecondsSinceEpoch = Date.now();

    // Convert milliseconds to seconds by dividing by 1000
    const secondsSinceEpoch = Math.floor(millisecondsSinceEpoch / 1000);

    if(response && response.data && response.data.listings){
      this.setState({
        multifamilyListings: response.data.listings,
        multifamilyTimestamp: secondsSinceEpoch,
        multifamilytInProgress:false
      })
    }
  }

  luxeryCallback = (response) => {
    const millisecondsSinceEpoch = Date.now();

    // Convert milliseconds to seconds by dividing by 1000
    const secondsSinceEpoch = Math.floor(millisecondsSinceEpoch / 1000);

    if(response && response.data && response.data.listings){
      this.setState({
        luxeryListings: response.data.listings,
        luxeryTimestamp: secondsSinceEpoch,
        luxeryInProgress:false
      })
    }
  }

  getSelectedListing = () => {
    const filteredArr = this.state.listings.filter(obj => obj["ListingId"] === this.state.listingId);
    return filteredArr.length ? filteredArr[0] : null;
  }


  toggleListingReport = () => {
    this.setState({
      showListingReport: !this.state.showListingReport,
    });
  };

  displayListingReport = (listingid) => {
    this.setState({
      showListingReport: !this.state.showListingReport,
      listingId: listingid
    });
  }


  toggleFeaturedListings = () => {
    this.setState({
      FeaturedListings: !this.state.listingsListings,
      subService:"Featured Listings"
    });
  };

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };

  toggleSubServices = () => {
    this.setState({
      showCommunities: false,
      showLuxery: false,
      showLand: false,
      showMultifamily: false,
      showWaterfront: false,
      showRecent: false,
      showNewConstruction: false,
      subService: "Featured Listings"
    })
  }


  toggleCommunities = () => {
    this.setState({
      showCommunities: !this.state.showCommunities,
      showLuxery: false,
      showLand: false,
      showMultifamily: false,
      showWaterfront: false,
      showRecent: false,
      showNewConstruction: false,
      subService: !this.state.showCommunities ? "Local Villages" : "Featured Listings"
    })
  }

  toggleMillion = () => {
    this.setState({
      showCommunities: false,
      showLuxery: !this.state.showLuxery,
      showLand: false,
      showMultifamily: false,
      showWaterfront: false,
      showRecent: false,
      showNewConstruction: false,
      subService: !this.state.showLuxery ? "Luxery Listings" : "Featured Listings"
    })
  }

  toggleRecent = () => {


    const millisecondsSinceEpoch = Date.now();

    // Convert milliseconds to seconds by dividing by 1000
    const secondsSinceEpoch = Math.floor(millisecondsSinceEpoch / 1000);
    if(!this.state.showRecent && (this.state.recentListings.length == 0 || ((secondsSinceEpoch - this.state.recentTimestamp) > 3600 ))){
      this.setState({
        recentInProgress: true
      }, () => this.props.getFeaturedListings("Featured", "recent", this.recentCallback) )
      
    }

    this.setState({
      showCommunities: false,
      showLuxery: false,
      showLand: false,
      showMultifamily: false,
      showRecent: !this.state.showRecent,
      showWaterfront: false,
      showNewConstruction: false,
      subService: !this.state.showRecent ? "Just Listed" : "Featured Listings"
    })
  }

  toggleLand = () =>  {

    const millisecondsSinceEpoch = Date.now();

    // Convert milliseconds to seconds by dividing by 1000
    const secondsSinceEpoch = Math.floor(millisecondsSinceEpoch / 1000);
    if(!this.state.showLand && (this.state.landListings.length == 0 || ((secondsSinceEpoch - this.state.landTimestamp) > 3600 ))){
      this.setState({
        landInProgress: true
      }, () => this.props.getFeaturedListings("Featured", "land", this.landCallback))
    }
    

    this.setState({
      showCommunities: false,
      showLuxery: false,
      showRecent: false,
      showLand: !this.state.showLand,
      showMultifamily: false,
      showWaterfront: false,
      showNewConstruction: false,
      subService: !this.state.showLand ? "With Land (1+ Acre)" : "Featured Listings"
    })
  }


  toggleWaterfront = () => {

    const millisecondsSinceEpoch = Date.now();

    // Convert milliseconds to seconds by dividing by 1000
    const secondsSinceEpoch = Math.floor(millisecondsSinceEpoch / 1000);
    if(!this.state.showWaterfront && (this.state.waterfrontListings.length == 0 || ((secondsSinceEpoch - this.state.waterfrontTimestamp) > 3600 ))){
      this.setState({
        waterfrontInProgress: true
      }, () => this.props.getFeaturedListings("Featured", "waterfront", this.waterfrontCallback))
    }

    this.setState({
      showCommunities: false,
      showLuxery: false,
      showLand: false,
      showMultifamily: false,
      showWaterfront: !this.state.showWaterfront,
      showNewConstruction: false,
      showRecent: false,
      subService: !this.state.showWaterfront ? "Waterfront" : "Featured Listings"
    })
  }

  toggleNewConstruction = () => {

    const millisecondsSinceEpoch = Date.now();

    // Convert milliseconds to seconds by dividing by 1000
    const secondsSinceEpoch = Math.floor(millisecondsSinceEpoch / 1000);
    if(!this.state.showNewConstruction && (this.state.newConstructionListings.length == 0 || ((secondsSinceEpoch - this.state.newConstructionTimestamp) > 3600 ))){
      this.setState({
        newConstructionInProgress: true
      }, () => this.props.getFeaturedListings("Featured", "tobebuilt", this.newConstructionCallback))
    }

    this.setState({
      showCommunities: false,
      showLuxery: false,
      showLand: false,
      showMultifamily: false,
      showWaterfront: false,
      showRecent: false,
      showNewConstruction: !this.state.showNewConstruction,
      subService: !this.state.showNewConstruction ? "New Builds" : "Featured Listings"
    })
  }
  

  toggleOlderListings = () => {
    this.setState({
      showCommunities: false,
      showLuxery: false,
      showLand: false,
      showMultifamily: false,
      showWaterfront: false,
      showNewConstruction: false,
      showRecent: false,
      showOlderListings: !this.state.showOlderListings,
      subService: !this.state.showOlderListings ? "Older Listings" : "Featured Listings"
    })
  }

  toggleForeclosureListings = () => {
    this.setState({
      showCommunities: false,
      showLuxery: false,
      showLand: false,
      showMultifamily: false,
      showWaterfront: false,
      showNewConstruction: false,
      showRecent: false,
      showCoreclosureListings: !this.state.showForeclosureListings,
      subService: !this.state.showForeclosureListings ? "Foreclosures" : "Featured Listings"
    })
  }

  toggleMultifamily = () => {

    const millisecondsSinceEpoch = Date.now();

    // Convert milliseconds to seconds by dividing by 1000
    const secondsSinceEpoch = Math.floor(millisecondsSinceEpoch / 1000);
    if(!this.state.showLand && (this.state.multiFamilyListingsListings.length == 0 || ((secondsSinceEpoch - this.state.multifamilyTimestamp) > 3600 ))){
      this.props.getFeaturedListings("Featured", "multifamily", this.multifamilyCallback)
    }


    this.setState({
      showCommunities: false,
      showLuxery: false,
      showLand: false,
      showMultifamily: !this.state.showMultifamily,
      showWaterfront: false,
      showNewConstruction: false,
      showRecent: false,
      showForeclosureListings: false,
      subService: !this.state.showMultifamily ? "Multi Family" : "Featured Listings"
    })
  }

  toggleLuxery = () => {

    const millisecondsSinceEpoch = Date.now();

    // Convert milliseconds to seconds by dividing by 1000
    const secondsSinceEpoch = Math.floor(millisecondsSinceEpoch / 1000);
    if(!this.state.showLuxery && (this.state.luxeryListings.length == 0 || ((secondsSinceEpoch - this.state.luxeryTimestamp) > 3600 ))){
      this.setState({
        luxeryInProgress: true
      }, () => this.props.getFeaturedListings("Featured", "luxery", this.luxeryCallback))
    }


    this.setState({
      showCommunities: false,
      showLuxery: !this.state.showLuxery,
      showLand: false,
      showMultifamily: false,
      showWaterfront: false,
      showNewConstruction: false,
      showRecent: false,
      showForeclosureListings: false,
      subService: !this.state.showLuxery ? "Luxery" : "Featured Listings"
    })
  }


  getContactTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "Request A Showing",
      summary:
      "I will work to ensure that your journey is as smooth and stress-free as possible. Whether you are a first-time buyer or an experienced home owner, I am here to support you every step of the way.",
      download: true,
    }

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "15px",
            height: {
              xl: "75%",
              lg: "75%",
              md: "75%",
              sm: "100%",
            },
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTopLeftIcon={null}
            tagTopLeftTxt={null}
            tagTopLeftPadding={"5px 10px 5px 10px"}
            tagTopLeftTxtColor={"black"}
            tagTopLeftTxtSize={null}
            tagTopLeftPaddingLeft={"0px"}
            tagTopLeftPaddingTop={"0%"}
            tagTopLeftTxtType={"bold"}
            tagTopLeftTxtAlign={"center"}
            tagTopLeftBgColor={null}
            tagTopLeftBorder={"0px solid black"}
            tagTopLeftBorderRadius={"0px"}
            topPadding={"25px"}
            topPicture={"profilepics/feature-profile.png"}
            topPictureAlign={"50% 100%"}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "6px", "20px"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"flex-end"}
            topVerticalAlign={"flex-end"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["250px", "80%", "80%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={null
            }
            mdlCallback={null}
            mdlTxtColor={this.props.theme.bannerColor}
            mdlTxtSize={["1rem", "1rem", "1.2rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerTextColor}
            mdlBorder={"1px solid black"}
            mdlHeight={["0px", "0%", "0%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
              <React.Fragment>      
              <Button
              onClick={() => this.props.showContact()}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.buttonTextColor,
                  background:this.props.theme.buttonHoverColor
                },
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                width:"95%",
                padding: this.state.appSize === "small" ? "5px" : "10px",
                marginRight: "0px",
                textTransform: "none",
                textAlign: "center",
                color:this.props.theme.buttonTextColor,
                border:"1px solid " + this.props.theme.buttonColor,
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1rem",
                  lg:".75rem",
                  xl:".75rem",
                }
              }}
            >Lets Talk, Text Or Email</Button>
              </React.Fragment>      
            }
            btmWidget={null}
            btmCallback={null}
            btmTxtColor={this.props.theme.bannerTextColor}
            btmTxtSize={["1rem","1rem","1rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={this.props.theme.bannerColor}
            btmBorder={"1px solid " + this.props.theme.bannerColor}
            btmHeight={["100px", "20%", "20%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"5px solid "+ this.props.theme.bannerColor}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  getBlankTile = () => {

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "15px",
            height: {
                xl: "100%",
                lg: "100%",
                md: "100%",
                sm: "100%",
            },
          }}
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
        </Grid>
      </React.Fragment>
    );
  };


  downloadResources = (filename) => {
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }


  createSection = (section, title, intro, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,.75)",
          height: "100%",
          width: "100%",
          borderBottom: "0px solid #333333",
          overflowY:"hidden",
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "7%",
              lg: "7%",
              md: "8%",
              sm: "7%",
              xs: "10%",
            },
            padding: "0px",
            fontSize: "2rem",
            borderBottom: "0px solid #333333",
          }}
          xs={12}
        >

          <Grid
              container
              ref={section}
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{          
                padding:"0px",
                borderBottom:"0px solid #AAAAAA",
                background: this.props.theme.bannerColor,
                width: "100%",
                height:"100%"
              }}
            >
                  <Button
                      onClick={this.toggleWaterfront}
                      variant={this.props.theme.buttonVariant}
                      InputProps={{
                        style:{
                          borderBottom:"1px soild #AAAAAA",
                      }}}
                      sx={{
                        '&:hover': {
                          border: this.state.showWaterfront ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,
                          color: this.props.theme.bannerTextColor,
                          background:this.props.theme.buttonHoverColor
                        },

                        marginRight:"0px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        textTransform: "none",
                        textAlign: "left",
                        padding:{
                          xs: "15px 15px 10px 15px",
                          sm: "15px 15px 10px 10px",
                          md: "15px 15px 15px 10px",
                          lg: "10px 15px 8px 10px",
                          xl: "10px 15px 8px 10px",
                        },
                        background: this.state.showWaterfront ? this.props.theme.bannerColor : "#EEEEEE",
                        color: this.state.showWaterfront ? this.props.theme.bannerTextColor :"#333333",
                        border: this.state.showWaterfront ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,      
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        width:{
                          xs:"50%",
                          md:"20%"
                        },
                        height:{
                          md:"100%"
                        }
                      }}
                    >
                        Waterfront
                    </Button>


                    <Button
                      onClick={this.toggleLand}
                      variant={this.props.theme.buttonVariant}
                      InputProps={{
                        style:{
                          borderBottom:"1px soild #AAAAAA",
                      }}}
                      sx={{
                        '&:hover': {
                          border: this.state.showLand ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,
                          color: this.props.theme.bannerTextColor,
                          background:this.props.theme.buttonHoverColor
                        },

                        marginRight: "0px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottom:"1px solid #AAAAAA",
                        textTransform: "none",
                        textAlign: "left",
                        padding:{
                          xs: "15px 15px 10px 15px",
                          sm: "15px 15px 10px 10px",
                          md: "15px 15px 15px 10px",
                          lg: "10px 15px 8px 10px",
                          xl: "10px 15px 8px 10px",
                        },
                        background: this.state.showLand ? this.props.theme.bannerColor : "#EEEEEE",
                        color: this.state.showLand ? this.props.theme.bannerTextColor :"#333333",
                        border: this.state.showLand ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,      
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        width:{
                          xs:"50%",
                          md:"20%"
                        },
                        height:{
                          md:"100%"
                        }
                      }}
                    >
                      With Land (1+ Acres)
                    </Button>

                    <Button
                      onClick={this.toggleRecent}
                      variant={this.props.theme.buttonVariant}
                      InputProps={{
                        style:{
                          borderBottom:"1px soild #AAAAAA",
                      }}}
                      sx={{
                        '&:hover': {
                          border: this.state.showRecent ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,
                          color: this.props.theme.bannerTextColor,
                          background:this.props.theme.buttonHoverColor
                        },

                        marginRight: "0px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottom:"1px solid #AAAAAA",
                        textTransform: "none",
                        textAlign: "left",
                        padding:{
                          xs: "15px 15px 10px 15px",
                          sm: "15px 15px 10px 10px",
                          md: "15px 15px 15px 10px",
                          lg: "10px 15px 8px 10px",
                          xl: "10px 15px 8px 10px",
                        },
                        background: this.state.showRecent ? this.props.theme.bannerColor : "#EEEEEE",
                        color: this.state.showRecent ? this.props.theme.bannerTextColor :"#333333",
                        border: this.state.showRecent ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,      
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        width:{
                          xs:"50%",
                          md:"20%"
                        },
                        height:{
                          md:"100%"
                        }
                      }}
                    >
                      Just Listed
                    </Button>

                    <Button
                      onClick={this.toggleLuxery}
                      variant={this.props.theme.buttonVariant}
                      InputProps={{
                        style:{
                          borderBottom:"1px soild #AAAAAA",
                      }}}
                      sx={{
                        '&:hover': {
                          border: this.state.showLuxery ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,
                          color: this.props.theme.bannerTextColor,
                          background:this.props.theme.buttonHoverColor
                        },
            
                        marginRight: "0px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottom:"1px solid #AAAAAA",
                        textTransform: "none",
                        textAlign: "left",
                        padding:{
                          xs: "15px 15px 10px 15px",
                          sm: "15px 15px 10px 10px",
                          md: "15px 15px 15px 10px",
                          lg: "10px 15px 8px 10px",
                          xl: "10px 15px 8px 10px",
                        },
                        background: this.state.showLuxery ? this.props.theme.bannerColor : "#EEEEEE",
                        color: this.state.showLuxery ? this.props.theme.bannerTextColor :"#333333",
                        border: this.state.showLuxery ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,      
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        width:{
                          xs:"50%",
                          md:"20%"
                        },
                        height:{
                          md:"100%"
                        }
                      }}
                    >
                    Million Dollar Listings
                    </Button>

                    <Button
                      onClick={this.toggleNewConstruction}
                      variant={this.props.theme.buttonVariant}
                      InputProps={{
                        style:{
                          borderBottom:"1px soild #AAAAAA",
                      }}}
                      sx={{
                        '&:hover': {
                          border: this.state.showNewConstruction ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,
                          color: this.props.theme.bannerTextColor,
                          background:this.props.theme.buttonHoverColor
                        },
            
                        marginRight: "0px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottom:"1px solid #AAAAAA",
                        textTransform: "none",
                        textAlign: "left",
                        padding:{
                          xs: "15px 15px 10px 15px",
                          sm: "15px 15px 10px 10px",
                          md: "15px 15px 15px 10px",
                          lg: "10px 15px 8px 10px",
                          xl: "10px 15px 8px 10px",
                        },
                        background: this.state.showNewConstruction ? this.props.theme.bannerColor : "#EEEEEE",
                        color: this.state.showNewConstruction ? this.props.theme.bannerTextColor :"#333333",
                        border: this.state.showNewConstruction ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,      
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        width:{
                          xs:"100%",
                          md:"20%"
                        },
                        height:{
                          md:"100%"
                        }
                      }}
                    >
                    New Builds
                    </Button>
             </Grid>
        </Grid>

        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "93%",
              lg: "93%",
              md: "92%",
              sm: "68%",
            },
            overflowY:"auto",
            paddingTop: "10px",
            fontSize: "2rem",            
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              
              height:"100%",
              width: "100%",
            }}
          >
            {this.state.listings.length >= 1 && this.state.listings.map((tile, index) => {
              return (
                this.getFeaturedListingsTile(tile)
              )
            })}

            {this.state.listings.length < 3 ? this.getContactTile():null}

            {this.getIdxTile()}          

            <Grid
              item
              sx={{
                width: "100%",
                height: {
                  xl: "10%",
                  lg: "10%",
                  md: "10%",
                  sm: "10%",
                },
                padding: "5px",
                fontSize: "2rem",
              }}
              xs={12}
            >
              <Footer theme={this.props.theme}  />
            </Grid>

          </Grid>


        </Grid>
          

        
      </Grid>
    );
  };

  getIdxTile = () => {

    
    const currentDate = new Date();

    // Get day, month, year components
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Month is zero-based, so add 1
    const year = currentDate.getFullYear();

    // Get time components
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const localTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Format the output
    const formattedTimestamp = `${day}/${month}/${year} ${hours}:${paddedMinutes}:${seconds} ${localTimeZoneName}`;

    return (
        <Grid
          item
          sx={{
            background: "rgba(255,255,255,0)",
            padding: "0px",
            height: {
              xl: "10%",
              lg: "15%",
              md: "15%",
              sm: "50%",
            },
            border:"0px solid black"
          }}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
         <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"0px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"0px"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "0px", "0px"]}
            topTxtType={"normal"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["0px", "0%", "0%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={null}
            mdlTxtColor={"black"}
            mdlTxtSize={["0px", "0px", "0px"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"flex-start"}
            mdlBgColor={"rgba(255,255,255,0)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["0px", "0%", "0%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          background: "rgba(255,255,255,0)",
                          height:"100%",
                          width:"100%",     
                          border: "0px solid #AAAAAA",
                        }}
                      >
                        <Grid
                          item
                          sx={{
                            background: "rgba(255,255,255,0)",
                            overflowX:"hidden",
                            textAlign:"right",
                            padding:"0px 10px 0px 0px",
                            fontSize: {
                              xs:"1rem",
                              sm:"1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem"
                            },
                            height:"100%",
                            width:"100%",   
                            border:"0px solid black",
                            fontWeight:"normal",
                          }}
                          xs={2}
                          sm={2}
                          md={1}
                        >
                          {this.state.listings && this.state.listings.length > 0?
                            <img alt="image" src="howardhanna/idx-lg.png" height={30} width={50}/>
                            :
                            null
                            }
                        </Grid>
                        <Grid
                          item
                          sx={{
                            background: "rgba(255,255,255,0)",
                            overflowX:"hidden",
                            textAlign:"left",
                            fontSize: {
                              xs:".55rem",
                              sm:".55rem",
                              md:".55rem",
                              lg:".55rem",
                              xl:".55rem"
                            },
                            height:"100%",
                            width:"100%",   
                            fontWeight:"normal",
                          }}
                          xs={10}
                        >         
                        {this.state.listings && this.state.listings.length > 0 ?                 
                          <div>
                          <div>The data relating to real estate on this web site comes in part from the Internet Data Exchange (IDX)  
                          Program of NYSAMLS’s. Real estate listings held by firms other than Howard Hanna Real Estate Services, are marked 
                          with the Listing Broker’s Firm Name. Data provided is for consumers’ personal, non-commercial use and 
                          may not be used for any purpose other than to identify prospective properties that consumers may be 
                          interested in purchasing. Listing Data last updated at { formattedTimestamp }</div>
                    
                          <div>Disclaimer: All information deemed reliable but not guaranteed and should be independently verified. All 
                          properties are subject to prior sale, change or withdrawal. Neither the listing broker(s) nor Howard Hanna Real  
                          Estate Services shall be responsible for any typographical errors, misinformation, misprints, and shall be held 
                          totally harmless. © {year} . CNYIS, UNYREIS, WNYREIS. All rights reserved.</div>
                          </div>
                          :
                          null}
                        </Grid>
                        <Grid
                          item
                          sx={{
                            background: "rgba(255,255,255,0)",
                            overflowX:"hidden",
                            textAlign:"right",
                            fontSize: {
                              xs:"1rem",
                              sm:"1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem"
                            },
                            height:"100%",
                            width:"100%",   
                            border:"0px solid black",
                            fontWeight:"normal",
                          }}
                          xs={0}
                          sm={0}
                          md={1}
                        >
                        </Grid>
                        </Grid>
            }
            btmTxtColor={"black"}
            btmTxtSize={["1rem", ".75rem", ".75rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"justify"}
            btmHorizontalAlign={"flex-end"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"rgba(255,255,255,0)"}
            btmBorder={"0px solid black"}
            btmHeight={["425px", "100%", "100%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"0px solid black"}
            shadow={0}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"10px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,0)"}
          />
        </Grid>
    );
  };


  


getFeaturedListingsTile = (tile) => {

    return (
            <Grid
              item
              sx={{
                width: "100%",
                padding: "15px",
                height: {
                  xl: "75%",
                  lg: "75%",
                  md: "75%",
                  sm: "100%",
                },
              }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
       <CommonTile
          uid={tile.ListingId}
          animationType={null}
          animationTime={null}
          tagTopRightIcon={ 
            this.props.favorites && this.props.favorites.some(obj => obj.ListingId === tile.ListingId) ? 
            <React.Fragment>
               <Image src={"logos/loveit-logo.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(tile)} fit="contain" height={35} width={55} duration={200}/>              
              </React.Fragment>              
              :
              <React.Fragment>
               <Image src={"logos/loveit-gray.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(tile)} fit="contain" height={35} width={55} duration={200}/>              
              </React.Fragment>                           
          }
          tagTopRightTxt={null}
          tagTopRightPadding={"2px 2px 2px 2px"}
          tagTopRightTxtColor={"black"}
          tagTopRightTxtSize={["1rem", "1rem", "1rem"]}
          tagTopRightPaddingRight={"2px"}
          tagTopRightPaddingTop={"2px"}
          tagTopRightTxtType={"bold"}
          tagTopRightTxtAlign={"center"}
          tagTopRightBgColor={"rgba(255,255,255,0)"}
          tagTopRightBorder={"0px solid white"}
          tagTopRightBorderRadius={["0px","0px","0px","0px"]}
          tagTopRightCallback={null}

          tagBottomRightIcon={
            tile.ListAgentMlsId === "WILLSDO" ? <Image src={"logos/listedby-donna.png"} bgColor="rgba(255,255,255,0)" fit="fit" height={24} width={72} duration={200}/> : null
          }
          tagBottomRightTxt={
            null
          }
          tagBottomRight          
          tagBottomRightPadding={"5px 10px 5px 10px"}
          tagBottomRightTxtColor={"black"}
          tagBottomRightTxtSize={[".75rem", ".75rem", ".75rem"]}
          tagBottomRightPaddingRight={"0px"}
          tagBottomRightPaddingBottom={"0px"}
          tagBottomRightTxtType={"bold"}
          tagBottomRightTxtAlign={"center"}
          tagBottomRightBgColor={this.props.theme.highlightColor}
          tagBottomRightBorder={"0px solid black"}
          tagBottomRightBorderRadius={["10px", "0px", "0px", "0px"]}
          tagBottomRightCallback={null}

          tagTopLeftIcon={null}
          tagTopLeftPadding={"5px 10px 5px 10px"}
          tagTopLeftTxtColor={tile.MlsStatus ? this.props.theme.bannerTextColor : "black"}
          tagTopLeftTxtSize={[".75rem", ".75rem",  ".75rem"]}
          tagTopLeftPaddingLeft={"0px"}
          tagTopLeftPaddingTop={"5px"}
          tagTopLeftTxtType={"bold"}
          tagTopLeftTxtAlign={"center"}
          tagTopLeftBgColor={tile.MlsStatus ? this.props.theme.bannerColor : ""}
          tagTopLeftBorder={"0px solid black"}
          tagTopLeftBorderRadius={["0px", "20px", "20px", "0px"]}
          tagTopLeftTxt={tile.MlsStatus ? tile.MlsStatus.replace(/([A-Z])/g, ' $1') : ""}

          tagBottomLeftIcon={null}
          tagBottomLeftTxt={tile.videos && tile.videos.length > 0 ?                 
            <Button variant="filled" size="small" sx={{padding:"5px 10px 5px 10px", textTransform: "none", fontSize:".55rem", color:this.props.theme.bannercolor}} startIcon={<PlayIcon sx={{color:this.props.theme.bannerColor}}/>}>
              Video
            </Button>                
            : null
          }
          tagBottomLeftPadding={"0px"}
          tagBottomLeftTxtColor={this.props.theme.bannerColor}
          tagBottomLeftTxtSize={["1rem", "1rem", ".75rem"]}
          tagBottomLeftPaddingLeft={"0px"}
          tagBottomLeftPaddingBottom={"5px"}
          tagBottomLeftTxtType={"bold"}
          tagBottomLeftTxtAlign={"center"}
          tagBottomLeftBgColor={"rgba(255,255,255,1)"}
          tagBottomLeftBorder={"0px solid black"}
          tagBottomLeftBorderRadius={["0px", "10px", "10px", "0px"]}
          tagBottomLeftCallback={this.displayListingReport}

          topCallback={this.displayListingReport}
          topPadding={"0px"}
          topPicture={null}
          topPictureAlign={"center"}
          topPictureSize={"cover"}
          topTxt={null}
          topTxtColor={"white"}
          topTxtSize={["1.5rem", "1.5rem", ".75rem"]}
          topTxtType={"bold"}
          topTxtAlign={"left"}
          topVerticalAlign={"flex-end"}
          topHorizontalAlign={"flex-start"}
          topBgColor={"rgba(255,255,255,0)"}
          topHeight={["250px", "75%", "75%"]}
          topGraphType={null}
          topGraphData={null}
          topWidget={
            tile.Media && tile.Media.length > 0 ?
            <Image src={tile.Media[0]["MediaURL"]} fit="cover" duration={200} />
            :
            null
          }

          mdlTagIcon={null}
          mdlTagTxt={null}
          mdlTagPadding={"0px"}
          mdlTagTxtColor={"black"}
          mdlTagTxtSize={["1.5rem", "1.5rem", "1.5rem"]}
          mdlTagPaddingRight={"4px"}
          mdlTagPaddingTop={"2px"}
          mdlTagTxtType={"bold"}
          mdlTagTxtAlign={"center"}
          mdlTagBgColor={"rgba(255,255,255,0)"}
          mdlTagBorder={"0px solid black"}
          mdlTagBorderRadius={"5px"}                
          mdlPadding={"0px"}
          mdlPicture={null}
          mdlPictureAlign={null}
          mdlTxtLineHeight={1.5}
          mdlTxt={null}

          mdlWidget={
            
            <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "rgba(255,255,255,0)",
              height:"100%",
              width:"100%",     
              border: "0px solid #AAAAAA",
            }}
          >
              <Grid
                item
                sx={{
                  background: "rgba(255,255,255,0)",
                  overflowX:"hidden",
                  textAlign:"left",
                  fontSize: {
                    xs:"1rem",
                    sm:"1rem",
                    md:"1rem",
                    lg:"1rem",
                    xl:"1rem",
                  },
                  fontWeight:"normal",
                }}
                xs={9}
                sm={9}
                md={9}
                lg={10}
                xl={10}
                
              >

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    background: "rgba(255,255,255,0)",
                    height:"100%",
                    width:"100%",     
                    border: "0px solid #AAAAAA",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      overflowX:"hidden",
                      textAlign:"left",
                      fontSize: {
                        xs:"1rem",
                        sm:"1rem",
                        md:"1rem",
                        lg:"1rem",
                        xl:"1rem",
                      },
                      fontWeight:"normal",
                    }}
                    xs={12}
                  >
                      <Box sx={{
                        fontSize:{
                        xs:"1.25rem",
                        sm: "1.25rem",
                        md:"1.25rem",
                        lg:"1.25rem",
                        xl:"1.25rem",
                        },
                        paddingLeft:"10px",
                        fontWeight:"bold", display:"inline"}} >
                    {"$"+(tile.ListPrice ? tile.ListPrice.toLocaleString():"--")}
                    </Box>
                      &nbsp;|&nbsp;
                    {tile.LivingArea ? tile.LivingArea.toLocaleString() : "--"} sf &nbsp;|&nbsp;
                    {tile.BedroomsTotal} bd&nbsp;|&nbsp;
                    {tile.BathroomsFull}.{tile.BathroomsHalf > 0 ? "5":"0"} ba
                  </Grid>                           

                  <Grid
                    item
                    sx={{
                      overflowX:"hidden",
                      overflowY:"hidden",

                      textAlign:"left",
                      paddingLeft:"10px",
                      fontSize: {
                        xs:"1rem",
                        sm: "1rem",
                        md:"1rem",
                        lg:"1rem",
                      },
                      lineHeight:"1"
                    }}
                    xs={12}
                  >
                  {tile.UnparsedAddress + ", " + tile.PostalCity}
                </Grid>                 
              </Grid>                 
              </Grid>                 

              <Grid
                item
                sx={{
                  overflowX:"hidden",
                  overflowY:"hidden",

                  textAlign:"left",
                  fontSize: {
                    xs:"1rem",
                    sm:"1rem",
                    md:"1rem",
                    lg:"1rem",
                    xl:"1rem",
                  },
                  fontWeight:"normal",
                  height:"100%",
                  width:"100%",     
                }}
                xs={3}
                sm={3}
                md={3}
                lg={2}
                xl={2}
                
              >

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: "rgba(255,255,255,0)",
                  height:"100%",
                  width:"100%",     
                  border: "0px solid #AAAAAA",
                  cursor:"pointer",
                  paddingBottom:
                  {
                    lg:"0px",
                  },
                
                }}
              >
                {tile.ListAgentMlsId === "WILLSDO"?
                 <Image src={"profilepics/profile-round-lowres.png"} bgColor="rgba(255,255,255,0)" fit="contain" duration={200}/>              
                  :
                  null
              }
              </Grid>

              </Grid>
           </Grid>            
          
            }
          mdlTxtColor={"black"}
          mdlTxtSize={["1rem", "1rem",  "1rem"]}
          mdlTxtType={"normal"}
          mdlTxtAlign={"center"}
          mdlHorizontalAlign={"center"}
          mdlVerticalAlign={"flex-end"}
          mdlBgColor={tile.ListAgentMlsId === "WILLSDO" ? this.props.theme.highlightColor : "#FFFFFF" }
          mdlBorder={tile.ListAgentMlsId === "WILLSDO" ? "1px solid " + this.props.theme.highlightColor : "0px solid black"}
          mdlHeight={["75px", tile.ListAgentMlsId === "WILLSDO" ? "20%" : "20%", "20%" ]}
          mdlGraphType={null}
          mdlGraphData={null}                
          btmPadding={"0px"}
          btmPicture={null}
          btmPictureAlign={null}
          btmTxt={ 
            <center>
              <div style={{fontSize:".6rem"}}>
              <img alt="image" src="howardhanna/idx-logo-sm.png" height="15px"/>  MLS:  {tile.ListingId} - Listing By {tile.ListOfficeName}
              </div>
            </center>      
          }
          btmTxtColor={"#333333"}
          btmTxtSize={[".75rem", ".75rem", "1rem"]}
          btmTxtType={"normal"}
          btmTxtAlign={"left"}
          btmHorizontalAlign={"center"}
          btmVerticalAlign={"center"}
          btmBgColor={tile.ListAgentMlsId === "WILLSDO" ? this.props.theme.highlightColor: "#FFFFFF" }
          btmBorder={"0px solid black"}
          btmHeight={["25px", tile.ListAgentMlsId === "WILLSDO" ? "5%" : "5%", "5%"]}
          btmGraphType={null}
          btmGraphData={null}
          border={tile.ListAgentMlsId === "WILLSDO" ? "2px solid " + this.props.theme.highlightColor : "2px solid #FFFFFF" }
          shadow={4}
          downloads={""}
          linksTo={""}
          growTime={4000}
          padding={"0px"}
          borderRadius={null}
          bgcolor={"rgba(255,255,255,1)"}
        />
         </Grid>
          );
  };

 
  render() {

 
    return (
      <React.Fragment>
      <Drawer
          anchor="bottom"
          open={this.props.showFeaturedListings}
          onClose={this.props.toggleFeaturedListings}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "88%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",
              marginLeft: "auto",
              pointerEvents:"auto",
              zINdex:this.props.zindex,

              backgroundImage: "url('"+ this.props.theme.background +"')",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"1px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "5px",
              borderBottom: "0px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                padding: "0px 0px 0px 5px",               
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem",
                },             
                color:this.props.theme.bannerTextColor  
              }}
            >
              {this.state.subService}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "center",
              padding: "10px",
              borderBottom: "0px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact()}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  marginRight: "5px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.state.showMultifamily || this.state.showNewConstruction || this.state.showMultifamily || this.state.showRecent || this.state.showWaterfront || this.state.showLand || this.state.showLuxery ? this.toggleSubServices : this.props.toggleFeaturedListings}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  marginRight: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "0px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.sellingAHomeSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top FeaturedListingsage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>

      </Grid>

      </Drawer>
            <ListingReport
              parentPage="Featured"
              listing={this.getSelectedListing()}
              showListingReport={this.state.showListingReport}
              toggleListingReport={this.toggleListingReport}              
              showRequestShowing={this.showRequestShowing}
              getFinanceDownload={this.props.getFinanceDownload}
              requestShowing={this.props.requestShowing}
              showContact={this.props.showContact}
              favorites={this.props.favorites}
              markAsFavorite={this.props.markAsFavorite}
              displayRequestShowing={this.props.displayRequestShowing}
              getPhotoReport={this.props.getPhotoReport}
              theme={this.props.theme}
            />

            <FeaturedListingsCommunities
              parentPage={this.state.parentPage}
              showFeatured={this.state.showCommunities}
              toggleFeatured={this.toggleCommunities}
              requestShowing={this.props.requestShowing}
              displayRequestShowing={this.props.displayRequestShowing}            
              showContact={this.showContact}
              theme={this.props.theme}
              getPhotoReport={this.props.getPhotoReport}
            />
    
            <FeaturedListingsRecent
              parentPage={this.state.parentPage}
              showFeatured={this.state.showRecent}
              toggleFeatured={this.toggleRecent}
              requestShowing={this.props.requestShowing}
              displayRequestShowing={this.props.displayRequestShowing}            
              showContact={this.showContact}
              theme={this.props.theme}
              getFeaturedListings={this.props.getFeaturedListings}
              listings={this.state.recentListings}
              favorites={this.props.favorites}
              markAsFavorite={this.props.markAsFavorite}
              getPhotoReport={this.props.getPhotoReport}
              inProgress={this.state.recentInProgress}
            />

            <FeaturedListingsLand
              parentPage={this.state.parentPage}
              showFeatured={this.state.showLand}
              toggleFeatured={this.toggleLand}
              requestShowing={this.props.requestShowing}
              displayRequestShowing={this.props.displayRequestShowing}            
              showContact={this.showContact}
              theme={this.props.theme}
              getFeaturedListings={this.props.getFeaturedListings}
              listings={this.state.landListings}
              favorites={this.props.favorites}
              markAsFavorite={this.props.markAsFavorite}
              getPhotoReport={this.props.getPhotoReport}      
              inProgress={this.state.landInProgress}
        
            />

            <FeaturedListingsMultifamily
              parentPage={this.state.parentPage}
              showFeatured={this.state.showMultifamily}
              toggleFeatured={this.toggleMultifamily}
              requestShowing={this.props.requestShowing}
              displayRequestShowing={this.props.displayRequestShowing}            
              showContact={this.showContact}
              theme={this.props.theme}
              getFeaturedListings={this.props.getFeaturedListings}
              listings={this.state.multiFamilyListings}
              favorites={this.props.favorites}
              markAsFavorite={this.props.markAsFavorite}
              getPhotoReport={this.props.getPhotoReport}
              inProgress={this.state.multiFamilyInProgress}

            />

            <FeaturedListingsWaterfront
              parentPage={this.state.parentPage}
              showFeatured={this.state.showWaterfront}
              toggleFeatured={this.toggleWaterfront}
              requestShowing={this.props.requestShowing}
              displayRequestShowing={this.props.displayRequestShowing}            
              showContact={this.showContact}
              theme={this.props.theme}
              getFeaturedListings={this.props.getFeaturedListings}
              listings={this.state.waterfrontListings}
              favorites={this.props.favorites}
              markAsFavorite={this.props.markAsFavorite}
              getPhotoReport={this.props.getPhotoReport}
              inProgress={this.state.waterfrontInProgress}

            />

            <FeaturedListingsNewConstruction
              parentPage={this.state.parentPage}
              showFeatured={this.state.showNewConstruction}
              toggleFeatured={this.toggleNewConstruction}
              requestShowing={this.props.requestShowing}
              displayRequestShowing={this.props.displayRequestShowing}            
              showContact={this.showContact}
              theme={this.props.theme}
              getFeaturedListings={this.props.getFeaturedListings}
              listings={this.state.newConstructionListings}
              favorites={this.props.favorites}
              markAsFavorite={this.props.markAsFavorite}
              getPhotoReport={this.props.getPhotoReport}
              inProgress={this.state.newConstructionInProgress}

            />

            <FeaturedListingsLuxery
              parentPage={this.state.parentPage}
              showFeatured={this.state.showLuxery}
              requestShowing={this.props.requestShowing}
              toggleFeatured={this.toggleLuxery}
              displayRequestShowing={this.props.displayRequestShowing}            
              showContact={this.showContact}
              theme={this.props.theme}
              getFeaturedListings={this.props.getFeaturedListings}
              listings={this.state.luxeryListings}
              favorites={this.props.favorites}
              markAsFavorite={this.props.markAsFavorite}
              getPhotoReport={this.props.getPhotoReport}
              inProgress={this.state.luxeryInProgress}

            />

        </React.Fragment>          
    );
  }
}

export default FeaturedListings;
