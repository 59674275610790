import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MagazineComponent from "./MagazineComponent";
import React, { Component } from "react";
import Footer from "./Footer";
import RequestShowing from "./RequestShowing";
import HowardHanna from './HowardHanna';
import RecentlySold from './RecentlySold';
import MyServicesRelocation from './MyServicesRelocation';
import FairHousing from './FairHousing';
import MyServices from './MyServices';
import About from './About';
import Financing from './Financing';
import Cookies from './Cookies';
import HomeValue from './HomeValue';
import HomeMarketReport from './HomeMarketReport';
import SearchListings from './SearchListings';
import BuyingAHome from './BuyingAHome';
import SellingAHome from './SellingAHome';
import UltimateGuides from './UltimateGuides';
import OurTeam from './team/OurTeam';
import FavoriteListings from './FavoriteListings';
import FeaturedListings from './FeaturedListings';
import MainMenu from './MainMenu';
import Contact from './Contact';
import Image from 'mui-image'
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import DwrServices from "./services/DwrServices";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';

class HomeSearchComponent extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();

    //this.restServices = new DwrServices(this.baseUrl + '');    
    this.containerRef = React.createRef(null)

    this.markers = [];
    this.listing = null;
    this.state = {
      requestid:null,
      selectedTowns: [],
      parentPage: "Homepage",
      doIntro: false,
      appSize: "large",
      howardzindex:1000,
      myserviceszindex:1000,
      financingzindex:1000,
      homevaluezindex:1000,
      searchlistingszindex:1000,
      small:{
        mapWidth:7,
        tileHeight: "40%",        
        fontSize:".8rem",
        fontSmSize:".5rem",
        fontLgSize:"1rem",
        topHeight: "70%",
        mdlHeight: "20%",
        btmHeight: "10%"
      },
      large:{
        mapWidth:6,
        tileHeight: "60%",        
        fontSize:"1rem",
        fontSmSize:".75rem",
        fontLgSize:"1.2rem",
        topHeight: "70%",
        mdlHeight: "20%",
        btmHeight: "10%"
      },
      mapWidth: 7,
      tileHeight: "40%",      
      showListingReport: false,
      showRequestShowing: false,
      showMyServices: false,
      showHowardHanna: false,
      showRecentlySold: false,
      showRelocation:false,
      showFeaturedListings: false,
      showFairHousing: false,
      showFinancing: false,
      showHomeValue: false,
      showHomeMarketReport: false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showUltimateGuides: false,
      showOurTeam: false,
      showContact: false,
      showAbout: false,
      showMainMenu: false,
      showCookies: false,
      featuredListings: false,
      gotoHome: null,
      favorites: [], 
      searchExtents: []
      

    };

    this.baseUrl = "https://buylovelist-server.azurewebsites.net"
    //this.baseUrl = "http://192.168.1.23:5050"

    /*
    this.timer1 = setTimeout(() => {
      this.setIntroDone();
      this.setState({showCookies:false})

    }, 5000);

    this.timer2 = setTimeout(() => {
      this.setEaseOut();
      //this.scrollTo100px()
    }, 4000);
    */
  }

  scrollTo100px = () => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth', // Use 'auto' for immediate scroll, 'smooth' for smooth scroll
    });  };


  componentDidMount() {

    let paramValue = null
    const urlParams = new URLSearchParams(window.location.search);

    // Check if there are any query parameters
    if (urlParams.has('loveit')) {
      // Query parameter exists
      paramValue = urlParams.get('loveit');


      if (typeof paramValue !== 'string') {
        return false;
      }

      const base64Regex = /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{4}|[A-Za-z0-9+\/]{3}=|[A-Za-z0-9+\/]{2}==)$/;

      if (!base64Regex.test(paramValue)) {
        paramValue = null
      }

      // Check if the string is a multiple of 4 and matches the regex
      //return base64Regex.test(str);

      // Do whatever you need with the parameter value
      // const isBase64 = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/.test(paramValue);
      
    }

    const uuid = uuidv4();
    let userdata = {
      "parentpage":"SessionPage",
      "sessionid": uuid,
      "loveit":paramValue
    }

    let temp = uuid.slice(0, -5) + "1" + uuid.slice(-4);
    this.setState({
      requestid: temp.slice(0, 4) + "a" + temp.slice(5)
    }, () => DwrServices.post(this.baseUrl + '/api/v1/propertyparser/requests/startsession', userdata, this.callback  ))
    
  }


  callback = (response) => {

    this.setState({
      sessionid: response.data.sessionid,
      recentlySold: response.data.recently_sold
    })
  }

  

  /////////////////////////////
  //
  // Services
  //
  /////////////////////////////
  getPlaces = (parentPage, address, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "address": address,
    }

    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/requests/places', userdata, callback )
  }



  getRequestConnect = (parentPage, name, phone, email, valuation, buying, selling, investment, commercial, building, relocation, land, jointheteam, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "name": name,
      "phone": phone,
      "email": email,
      "valuation": valuation,
      "buying": buying,
      "selling": selling,
      "investment": investment,
      "commercial": commercial,
      "building":building,
      "relocation":relocation,
      "jointheteam":jointheteam
    }

    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/requests/connect', userdata, callback )
  }


  getRequestMagazine = (parentPage, name, phone, email, address, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "name": name,
      "phone": phone,
      "email": email,
      "address": address,
    }

    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/requests/magazine', userdata, callback )
  }

  getValuationAppointment = (parentPage, name, phone, email, address, relocating, upordownsizing, buildorbuy, locations, timeframe, message, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "name": name,
      "phone": phone,
      "email": email,
      "address": address,
      "messsage": message,
      "relocating": relocating,
      "upordownsizing": upordownsizing,
      "buildorbuy": buildorbuy,
      "preferredlocations": locations,
      "timeframe": timeframe
    }
    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/requests/valuation', userdata, callback  )
  }

  getRequestShowing = (parentPage, name, phone, email, mslid, address, listprice, details, firstDay, firstTimes, secondDay, secondTimes, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "name": name,
      "phone": phone,
      "email": email,
      "mlsid": mslid,
      "address": address,
      "listprice": listprice,
      "details": details,
      "firstDay": firstDay,
      "firstTimes": firstTimes,
      "secondDay": secondDay,
      "secondTimes": secondTimes,
    }
    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/requests/showing', userdata, callback  )
  }

  getQueryReport = (parentPage, searchExtents, locations, styles, price, livingarea, bedrooms, bathrooms, musthaves, callback) => {

    

    if(locations.length > 0)
    {
      if(locations[0] == "Visible Map Area"){
        locations = ["Visible Map Area"].concat(searchExtents);
      }


      let userdata = {
        "requestid": this.state.requestid,
        "parentpage": parentPage,
        "locations": locations,
        "styles":styles,
        "price": price,
        "livingarea":livingarea,
        "bedrooms":bedrooms,
        "bathrooms":bathrooms,
        "musthaves":musthaves,
      }
      DwrServices.post(this.baseUrl + '/api/v1/propertyparser/reports/query', userdata, callback  )

    }

  }

  getPhotoReport = (parentPage, mlsid, count, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "mlsid": mlsid,
      "count":count,
    }
    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/reports/photos', userdata, callback  )
  }


  getHomeValueReport = (parentPage, streetNumber, streetName, townName, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "number": streetNumber,
      "street": streetName,
      "town": townName,
    }
    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/reports/homevalue', userdata, callback  )
  }

  getHomeValueDownload = (parentPage, streetNumber, streetName, townName, taxId, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "number": streetNumber,
      "street": streetName,
      "town": townName,
      "taxid": taxId
    }
    DwrServices.image(this.baseUrl + '/api/v1/propertyparser/downloads/homevalue', userdata, callback  )
  }


  getHomeMarketReport = (parentPage, streetNumber, streetName, townName, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "number": streetNumber,
      "street": streetName,
      "town": townName,
    }
    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/reports/homemarket', userdata, callback  )
  }

  getHomeMarketReportDownload = (parentPage, streetNumber, streetName, townName, taxId, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "number": streetNumber,
      "street": streetName,
      "town": townName,
      "taxid": taxId
    }
    DwrServices.image(this.baseUrl + '/api/v1/propertyparser/downloads/homemarket', userdata, callback  )
  }


  getFinanceDownload = (parentPage, principle, rate, term, pmi, taxes, insurance, hoa, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "principle": principle,
      "term": term,
      "rate": rate,
      "taxes":taxes,
      "pmi": pmi,
      "insurance": insurance,
      "hoa": hoa
    }
    DwrServices.image(this.baseUrl + '/api/v1/propertyparser/downloads/finance', userdata, callback)
  }

  getFavoritesDownload = (parentPage, mlsids, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "mslids": mlsids,
    }
    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/downloads/favorites', userdata, callback  )
  }

  getListingReport = (parentPage, mlsid, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "mslid": mlsid,
    }
    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/reports/listing', userdata, callback  )
  }

  getListingDownload = (parentPage, mlsid, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "mslid": mlsid,
    }
    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/downloads/listing', userdata, callback  )
  }

  getFeaturedListings = (parentPage, featuredType, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "featuredtype": featuredType
    }
    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/reports/featured', userdata, callback  )
  }

  getRecentlySold = (parentPage, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
    }
    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/reports/recently_sold', userdata, callback  )
  }

  getCommunityReport = (parentPage, community, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "community": community,
    }
    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/reports/snapshot', userdata, callback  )
  }

  getCommunityDownload = (parentPage, community, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "community": community,
    }
    DwrServices.image(this.baseUrl + '/api/v1/propertyparser/downloads/snapshot', userdata, callback  )
  }

  getUltimateGuideDownload = (parentPage, ultimateguide, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "ultimateguide": ultimateguide,
    }
    DwrServices.image(this.baseUrl + '/api/v1/propertyparser/downloads/ultimateguide', userdata, callback  )
  }

  getInformationDownload = (parentPage, infotype, callback) => {
    let userdata = {
      "requestid": this.state.requestid,
      "parentpage": parentPage,
      "information": infotype,
    }
    DwrServices.post(this.baseUrl + '/api/v1/propertyparser/downloads/information', userdata, callback  )
  }




  downloadResource = (filename) => {
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }

  setIntroDone = () => {
    this.setState({
      doIntro: false,
    });
  };

  setEaseOut = () => {
    this.setState({
      easeout: true,
    });
  };

  componentWillUnmount() {
    //clearTimeout(this.timer1);
    //clearTimeout(this.timer2);
  }

  toggleListingReport = () => {
    this.setState({
      showListingReport: !this.state.showListingReport,
    });
  };

  showListingReport = (listingid) => {
    this.setState({
      showListingReport: !this.state.showListingReport,
      listingId: listingid
    });
  }

  toggleRequestShowing = () => {
    this.setState({
      showRequestShowing: !this.state.showRequestShowing,
    });
  };

  displayRequestShowing = (listings) => {
    this.setState({
      showRequestShowing: !this.state.showRequestShowing,
      listingRequest: listings
    });
  }


  /*
  showRequestShowingOfFavorites = (id) => {

    let listings =  this.state.searchResults.map((listing, index) => {
        if(this.props.favorites.some(obj => obj.mls === tile.mls) 
){
          return listing
        }else{
          return null
        }
    }).filter((element) => element !== null);



    this.setState({
      showRequestShowing: !this.state.showRequestShowing,
      listingRequest: listings
    });
  }
 */
  
  markAsFavoriteold = (uid) => {
    const newArray = _.cloneDeep(this.state.favorites)
    if (newArray.includes(uid)){
      const filteredArray = newArray.filter((value) => value !== uid); // remove the value from the array
      this.setState({
        favorites: filteredArray
      })
    }
    else{
      newArray.push(uid)
      this.setState({
        favorites: newArray,
      })
    }
  }

  markAsFavorite = (listing) => {
    const newArray = _.cloneDeep(this.state.favorites)
    if (newArray.some(obj => obj.ListingId === listing.ListingId)){
      const filteredArray = newArray.filter((value) => value.ListingId !== listing.ListingId); // remove the value from the array
      this.setState({
        favorites: filteredArray
      })
    }
    else{
      newArray.push( _.cloneDeep(listing))
      this.setState({
        favorites: newArray,
      })
    }
  }


  /*
  * Howard Hanna
  */
  toggleCookies = () => {
    this.setState({
      showCookies: !this.state.showCookies,
    });
  };


  /*
  * Howard Hanna
  */
  toggleHowardHanna = () => {
    this.setState({
      showHowardHanna: !this.state.showHowardHanna,
    });
  };

  showHowardHanna = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: !this.state.showHowardHanna,
      showFairHousing: false,
      showRecentlySold: false,
      showRelocation:false,
      showMyServices: false,
      showFinancing: false,
      showHomeValue: false,
      showHomeMarketReport:false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showUltimateGuides: false,
      showOurTeam: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }

  /*
  * Recently Sold
  */
  toggleRecentlySold = () => {
    this.setState({
      showRecentlySold: !this.state.showRecentlySold,
    });
  };

  showRecentlySold = () => {
    this.setState({
      parentPage:"MainMenu",
      showRecentlySold: !this.state.showRecentlySold,
      showRelocation:false,
      showHowardHanna: false,
      showFairHousing: false,
      showMyServices: false,
      showFinancing: false,
      showHomeValue: false,
      showHomeMarketReport:false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showUltimateGuides: false,
      showOurTeam: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }

    /*
  * Relocation
  */
    toggleRelocation = () => {
      this.setState({
        showRelocation: !this.state.showRelocation,
      });
    };
  
    showRelocation = () => {
      this.setState({
        parentPage:"MainMenu",
        showRecentlySold: false,
        showRelocation: !this.state.showRelocation,
        showHowardHanna: false,
        showFairHousing: false,
        showMyServices: false,
        showFinancing: false,
        showHomeValue: false,
        showHomeMarketReport:false,
        showSpotlightListings: false,
        showFavoriteListings: false,
        showSearchListings: false,
        showBuyingAHome: false,
        showSellingAHome: false,
        showUltimateGuides: false,
        showOurTeam: false,
        showMainMenu: false,
        showFeaturedListings: false,
        showAbout: false,
      });
    }
  


  /*
  * FairHousing
  */
    toggleFairHousing = () => {
      this.setState({
        showFairHousing: !this.state.showFairHousing,
      });
    };
  
    showFairHousing = () => {
      this.setState({
        parentPage:"MainMenu",
        showFairHousing: !this.state.showFairHousing,
        showHowardHanna:false,
        showRecentlySold: false,
        showRelocation:false,
        showMyServices: false,
        showFinancing: false,
        showHomeValue: false,
        showHomeMarketReport:false,
        showSpotlightListings: false,
        showFavoriteListings: false,
        showSearchListings: false,
        showBuyingAHome: false,
        showSellingAHome: false,
        showUltimateGuides: false,
        showOurTeam: false,
        showMainMenu: false,
        showFeaturedListings: false,
        showAbout: false,
      });
    }
  

  /*
  * Howard Hanna
  */
  toggleMyServices = () => {
    this.setState({
      showMyServices: !this.state.showMyServices,
    });
  };

  showMyServices = () => {
    this.setState({
      parentPage:"MainMenu",
      showMyServices: !this.state.showMyServices,
      showHowardHanna: false,
      showRecentlySold: false,
      showRelocation:false,
      showFairHousing: false,      
      showFinancing: false,
      showHomeValue: false,
      showHomeMarketReport:false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showUltimateGuides: false,
      showOurTeam: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


  /*
  * Financing
  */
  toggleFinancing = () => {
    this.setState({
      showFinancing: !this.state.showFinancing,
    });
  };

  showFinancing = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showRecentlySold: false,
      showRelocation:false,
      showFairHousing: false,      
      showMyServices: false,
      showFinancing: !this.state.showFinancing,
      showHomeValue: false,
      showHomeMarketReport:false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showUltimateGuides: false,
      showOurTeam: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


  /*
  * Home Value
  */
  toggleHomeValue = () => {
    this.setState({
      showHomeValue: !this.state.showHomeValue,
    });
  };

  showHomeValue = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showRecentlySold: false,
      showRelocation:false,
      showFairHousing: false,      
      showMyServices: false,
      showFinancing: false,
      showHomeValue: !this.state.showHomeValue,
      showHomeMarketReport:false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showUltimateGuides: false,
      showOurTeam: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


  /*
  * Home Market Report
  */
  toggleHomeMarketReport = () => {
    this.setState({
      showHomeMarketReport: !this.state.showHomeMarketReport,
    });
  };

  showHomeMarketReport = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showRecentlySold: false,
      showRelocation:false,
      showFairHousing: false,      
      showMyServices: false,
      showFinancing: false,
      showHomeValue: false,
      showHomeMarketReport: !this.state.showHomeMarketReport,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showUltimateGuides: false,
      showOurTeam: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }

  
  /*
  * SpotlightListings
  */
  toggleSpotlightListings = () => {
    this.setState({
      showSpotlightListings: !this.state.showSpotlightListings,
    });
  };

  showSpotlightListings = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showRecentlySold: false,
      showRelocation:false,
      showFairHousing: false,      
      showMyServices: false,
      showFinancing: false,
      showHomeValue: false,
      showHomeMarketReport: false,
      showSpotlightListings: !this.state.showSpotlightListings,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showUltimateGuides: false,
      showOurTeam: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


  /*
  * FavoriteListings
  */
  toggleFavoriteListings = () => {
    this.setState({
      showFavoriteListings: !this.state.showFavoriteListings,
    });
  };

  showFavoriteListings = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showRecentlySold: false,
      showRelocation:false,
      showFairHousing: false,      
      showMyServices: false,
      showFinancing: false,
      showHomeValue: false,
      showHomeMarketReport: false,
      showFavoriteListings: !this.state.showFavoriteListings,
      showSpotlightListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showUltimateGuides: false,
      showOurTeam: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }



  /*
  * SearchListings
  */
  toggleSearchListings = () => {
    this.setState({
      showSearchListings: !this.state.showSearchListings,
    });
  };

  showSearchListings = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showRecentlySold: false,
      showRelocation:false,
      showFairHousing: false,      
      showMyServices: false,
      showFinancing: false,
      showHomeValue: false,
      showHomeMarketReport: false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: !this.state.showSearchListings,
      showBuyingAHome: false,
      showSellingAHome: false,
      showUltimateGuides: false,
      showOurTeam: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


  /*
  * Buying A Home
  */
    toggleBuyingAHome = () => {
      this.setState({
        showBuyingAHome: !this.state.showBuyingAHome,
      });
    };
  
    showBuyingAHome = () => {
      this.setState({
        parentPage:"MainMenu",
        showHowardHanna: false,
        showRecentlySold: false,
        showRelocation:false,
        showFairHousing: false,      
        showMyServices: false,
        showFinancing: false,
        showHomeValue: false,
        showHomeMarketReport: false,
        showSpotlightListings: false,
        showFavoriteListings: false,
        showSearchListings: false,
        showBuyingAHome: !this.state.showBuyingAHome,
        showSellingAHome: false,
        showUltimateGuides: false,
        showOurTeam: false,
        showMainMenu: false,
        showFeaturedListings: false,
        showAbout: false,
      });
    }

  /*
  * Selling A Home
  */
  toggleSellingAHome = () => {
    this.setState({
      showSellingAHome: !this.state.showSellingAHome,
    });
  };

  showSellingAHome = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showRecentlySold: false,
      showRelocation:false,
      showFairHousing: false,      
      showMyServices: false,
      showFinancing: false,
      showHomeValue: false,
      showHomeMarketReport: false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: !this.state.showSellingAHome,
      showUltimateGuides: false,
      showOurTeam: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


  /*
  * Selling A Home
  */
  toggleUltimateGuides = () => {
    this.setState({
      showUltimateGuides: !this.state.showUltimateGuides,
    });
  };

  showUltimateGuides = () => {
    this.setState({
      parentPage:"MainMenu",
      showHowardHanna: false,
      showRecentlySold: false,
      showRelocation:false,
      showFairHousing: false,      
      showMyServices: false,
      showFinancing: false,
      showHomeValue: false,
      showHomeMarketReport: false,
      showSpotlightListings: false,
      showFavoriteListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showUltimateGuides: !this.state.showUltimateGuides,
      showOurTeam: false,
      showMainMenu: false,
      showFeaturedListings: false,
      showAbout: false,
    });
  }


    /*
  * Selling A Home
  */
    toggleOurTeam = () => {
      this.setState({
        showOurTeam: !this.state.showOurTeam,
      });
    };
  
    showOurTeam = () => {
      this.setState({
        parentPage:"MainMenu",
        showHowardHanna: false,
        showRecentlySold: false,
        showRelocation:false,
        showFairHousing: false,      
        showMyServices: false,
        showFinancing: false,
        showHomeValue: false,
        showHomeMarketReport: false,
        showSpotlightListings: false,
        showFavoriteListings: false,
        showSearchListings: false,
        showBuyingAHome: false,
        showSellingAHome: false,
        showUltimateGuides: false,
        showOurTeam: !this.state.showOurTeam,
        showMainMenu: false,
        showFeaturedListings: false,
        showAbout: false,
      });
    }
  

  /*
  * Featured Listings
  */
    toggleFeaturedListings = () => {
      this.setState({
        showFeaturedListings: !this.state.showFeaturedListings,
      });
    };
  
    showFeaturedListings = () => {
      this.setState({
        parentPage:"MainMenu",
        showHowardHanna: false,
        showRecentlySold: false,
        showRelocation:false,
        showFairHousing: false,      
        showMyServices: false,
        showFinancing: false,
        showHomeValue: false,
        showHomeMarketReport: false,
        showSpotlightListings: false,
        showFavoriteListings: false,
        showSearchListings: false,
        showBuyingAHome: false,
        showSellingAHome: false,
        showUltimateGuides: false,
        showOurTeam: false,
        showMainMenu: false,
        showFeaturedListings:!this.state.showFeaturedListings,
        showAbout: false,
      });
    }
  
  
 /*
  * About
  */
    toggleAbout = () => {
      this.setState({
        showAbout: !this.state.showAbout,
      });
    };
  
    showAbout = () => {
      this.setState({
        parentPage:"MainMenu",
        showHowardHanna: false,
        showRecentlySold: false,
        showRelocation:false,
        showFairHousing: false,      
        showMyServices: false,
        showFinancing: false,
        showHomeValue: false,
        showHomeMarketReport: false,
        showSpotlightListings: false,
        showFavoriteListings: false,
        showSearchListings: false,
        showBuyingAHome: false,
        showSellingAHome: false,
        showUltimateGuides: false,
        showOurTeam: false,
        showMainMenu: false,
        showFeaturedListings:false,
        showAbout: false,
      });
    }
  

  /*
  * mainmenu
  */
    toggleMainMenu = () => {
      this.setState({
        showMainMenu: !this.state.showMainMenu,
      });
    };
  
    showMainMenu = () => {
      this.setState({
        showMainMenu: !this.state.showMainMenu,
      });
    }
  

  /*
  * Contact
  */
  toggleContact = () => {
    this.setState({
      showContact: !this.state.showContact,
      parentPage: null
    });
  };

  showContact = (parentPage, profilepic = "profile-round-lowres.png", name = "Donna Wills", title = "Licensed Real Estate Salesperson", email = "DonnaWills@HowardHanna.com", phone = "315 - 723 - 1077") => {
    this.setState({
      showContact: !this.state.showContact,
      profilepic: profilepic,
      name: name,
      title: title,
      email: email,
      phone: phone,
      parentPage: parentPage
    });
  }

  

  /*
  * Atoogel App Size
  */
  toggleAppSize = () => {
    if(this.state.appSize === "small"){
      this.setState({
        appSize:"large"
      })
    }else{
      this.setState({
        appSize:"small"
      })
    }
  }


  handleSearchItemChange = (event) => {
    this.setState({ selectedSearchItems: event.target.value });
  };


  updateSelectedTowns = (location) => {
    this.imageClickCallback(location)
  }


  imageClickCallback = (location) => {

      if(location === "North"){
        let north = []
        north = !this.state.selectedTowns.includes("Baldwinsville") ? [...north, "Baldwinsville"] : north
        north = !this.state.selectedTowns.includes("Brewerton") ? [...north, "Brewerton"] : north
        north = !this.state.selectedTowns.includes("Clay") ? [...north, "Clay"] : north
        north = !this.state.selectedTowns.includes("Liverpool") ? [...north, "Liverpool"] : north
        north = !this.state.selectedTowns.includes("Cicero") ? [...north, "Cicero"] : north
        this.setState(prevState => ({
          selectedTowns: [...prevState.selectedTowns, ...north],
        }));
      }else if(location === "West"){
          let west = []
          west = !this.state.selectedTowns.includes("Eldbridge") ? [...west, "Eldbridge"] : west
          west = !this.state.selectedTowns.includes("Camillus") ? [...west, "Camillus"] : west
          west = !this.state.selectedTowns.includes("Onopndaga") ? [...west, "Onondaga"] : west
          west = !this.state.selectedTowns.includes("Skaneateles") ? [...west, "Skaneateles"] : west
          this.setState(prevState => ({
            selectedTowns: [...prevState.selectedTowns, ...west],
          }));
        
      }else if(location === "South"){
        let south = []
        south = !this.state.selectedTowns.includes("Spafford") ? [...south, "Spafford"] : south
        south = !this.state.selectedTowns.includes("Lafayette") ? [...south, "Lafayette"] : south
        south = !this.state.selectedTowns.includes("Tully") ? [...south, "Tully"] : south
        south = !this.state.selectedTowns.includes("Fabius") ? [...south, "Fabius"] : south
        south = !this.state.selectedTowns.includes("Pompey") ? [...south, "Pompey"] : south
        this.setState(prevState => ({
          selectedTowns: [...prevState.selectedTowns, ...south],
        }));
      
      }else if(location === "East"){
        let east = []
        east = !this.state.selectedTowns.includes("Minoa") ? [...east, "Minoa"] : east
        east = !this.state.selectedTowns.includes("Manlius") ? [...east, "Manlius"] : east
        east = !this.state.selectedTowns.includes("Fayetteville") ? [...east, "Fayetteville"] : east
        east = !this.state.selectedTowns.includes("Jamesville") ? [...east, "Jamesville"] : east
        east = !this.state.selectedTowns.includes("DeWitt") ? [...east, "DeWitt"] : east
        this.setState(prevState => ({
          selectedTowns: [...prevState.selectedTowns, ...east],
        }));
      }else if(!this.state.selectedTowns.includes(location)){
          this.setState(prevState => ({
            selectedTowns: [...prevState.selectedTowns, location],
          }));
      }else{
          let new_array = this.state.selectedTowns.filter(item => item !== location);
          this.setState(prevState => ({
            selectedTowns: new_array
          }));
      }

  }

  getTeaserTile = () => {


    return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            padding:"0px",
            border:"0px solid red",
            width: {
              xs: "100%",
            },
            height: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
              xl: "100%",
            },
          }}
          >

{this.props.screenSize !== "xs"?
                        null

:
<Grow in={true} timeout={4000}>
          <Grid
            item
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              border: "0px solid yellow",
              height: {
                xs: "95%",
                sm: "95%",
                md: "95%",
                lg: "95%",
                xl: "95%",
              },
                            
              padding:{
                xs: "5px 5px 0px 5px",
                sm: "5px 5px 0px 5px",
                md: "10px",
                lg: "10px",
                xl: "10px",
              }
            }}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
          >

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "5px 0px 5px 0px",
                    md: "5px 0px 0px 10px",
                    lg: "5px 0px 0px 20px",
                    xl: "5px 0px 0px 20px"
                  },
                  border:"0px solid red",
                  width: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                  },
                  height: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                }}
                >
                    <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      height: {
                        xl: "95%",
                        lg: "95%",
                        md: "95%",
                        sm: "95%",
                        xs: "95%",
                      },
                      border:{
                        xs:"1px solid black",
                        sm:"1px solid black",
                        md:"2px solid #333333",
                        lg:"2px solid #333333",
                        xl:"2px solid #333333"
                      },
                        fontWeight: "normal",
                        padding: {
                          xs: "0px 0px 0px 0px",
                          sm: "10px 10px 5px 10px",
                          md: "0px 0px 0px 0px",
                          lg: "20px 20px 0px 20px",
                          xl: "0px 0px 0px 0px"
                        },
                        backgroundColor:"rgba(255,255,255,.75)",
                        borderRadius:"5px"
                          
                    }}
                    >

                        <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              sx={{
                                width: {
                                  xs: "100%",
                                },
                                height: {
                                  xs: "100%",
                                },
                                padding:"0px",
                                border:"0px solid black",
                                borderRadius:"0px"
                              }}
                              >
                                
                                <Grid
                                    item
                                    sx={{
                                      padding: "0px",
                                      textAlign: "center",
                                      fontSize: "1rem",
                                      height: "100%",
                                    }}
                                    xs={12}
                                 >
                                     <Grid
                                          container
                                          direction="row"
                                          justifyContent="center"
                                          alignItems="flex-start"
                                          sx={{
                                            cursor: "pointer",
                                            width: "100%",
                                            height: "100%",
                                            padding: {
                                              xs:"0px",
                                            },    
                                            fontWeight: "bold",
                                            border:"0px solid red",
                                            lineHeight: {
                                              xs: ".5",
                                            },
                                            fontSize: {
                                              xs: "1.5rem",
                                            },
                                            color:this.props.theme.textColor
                                          }}
                                        >
                                            <Grid
                                              item
                                              sx={{
                                                paddingTop: "20px",
                                                paddingBottom: "0px",
                                                textAlign: "center",
                                                fontSize: "2.5rem",
                                                height: "20%",
                                                border:"0px solid red",
                                              }}
                                              xs={12}
                                          >

                                            Donna Wills
                                            
                                            <font size="3">
                                              <br/>
                                              Licensed Real Estate Salesperson
                                              <br/>
                                              (315) 723-1077
                                              
                                              </font>
                                          </Grid>

                                          <Grid
                                              item
                                              sx={{
                                                "&:hover": {
                                                  cursor: "pointer",
                                                },
                                                padding: "10px",
                                                fontSize: "1rem",
                                                height: "8%",
                                                
                                                border:"0px solid red",
                                                textAlign:"center",
                                                fontWeight:"normal",
                                              }}
                                              onClick={() => this.showContact("Homepage")}
                                              xs={1}
                                          >

                                          </Grid>


                                          <Grid
                                              item
                                              sx={{
                                                "&:hover": {
                                                  cursor: "pointer",
                                                },
                                                paddingTop: "10px",
                                                paddingBottom: "10px",
                                                fontSize: "1rem",
                                                height: "8%",
                                                
                                                border:"0px solid red",
                                                textAlign:"center",
                                                fontWeight:"normal",
                                                borderTopLeftRadius: "24px",
                                                borderTopRightRadius: "24px",
                                                borderBottomRightRadius: "24px",
                                                borderBottomLeftRadius: "24px",
                                                background:this.props.theme.bannerColor,
                                                color:this.props.theme.bannerTextColor
                                              }}
                                              onClick={() => this.showContact("Homepage")}
                                              xs={10}
                                          >
                                          <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{
                                                  cursor: "pointer",
                                                  width: "100%",
                                                  height: "100%",
                                                  padding: {
                                                    xs:"0px",
                                                  },    
                                                  border:"0px solid red",
                                                }}
                                              >

                                              Contact Me
                                            </Grid>
                                          </Grid>

                                          <Grid
                                              item
                                              sx={{
                                                "&:hover": {
                                                  cursor: "pointer",
                                                },
                                                padding: "8px",
                                                fontSize: "1rem",
                                                height: "8%",
                                                
                                                border:"0px solid red",
                                                textAlign:"center",
                                                fontWeight:"normal",
                                              }}
                                              onClick={() => this.showContact("Homepage")}
                                              xs={1}
                                          >

                                          </Grid>

                                          <Grid
                                              item
                                              sx={{
                                                border:"0px solid red",
                                                paddingTop: "5px",
                                                textAlign: "center",
                                                height: {
                                                  xs: "60%",                                                  
                                                },
                                                backgroundImage: 'url("profilepics/profile-round-lowres.png")',
                                                backgroundPosition: "50% 100%",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                              }}
                                              xs={12}
                                              sm={12}
                                              md={0}
                                              lg={0}
                                              xl={0}
                                          >
                                            
                                          </Grid>                                          

                                  </Grid>
                                </Grid>
                              
                      </Grid>


                </Grid>

                </Grid>
              </Grid>
            </Grow>

}

{this.props.screenSize !== "xs"?

          <Grow in={true} timeout={4000}>

          <Grid
            item
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              border:"0px solid blue",
              height: {
                xs: "65%",
                sm: "65%",
                md: "93%",
                lg: "93%",
                xl: "93%",
              },
              
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              
            padding:{
              xs: "5px 5px 0px 5px",
              sm: "5px 5px 0px 5px",
              md: "5px 5px 0px 5px",
              lg: "5px 5px 0px 5px",
              xl: "5px 5px 0px 5px",
            },
            fontSize:{
              xs: "0px",
              sm: "0px",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            }

            }}
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
           >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "5px 0px 5px 0px",
                    md: "10px 0px 0px 10px",
                    lg: "25px 0px 0px 20px",
                    xl: "15px 0px 0px 10px"
                  },
                  border:"0px solid red",
                  width: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                  },
                  height: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                }}
                >
                    <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      height: {
                        xl: "95%",
                        lg: "95%",
                        md: "98%",
                        sm: "95%",
                        xs: "95%",
                      },
                      border:{
                        xs:"1px solid black",
                        sm:"1px solid black",
                        md:"2px solid #333333",
                        lg:"2px solid #333333",
                        xl:"2px solid #333333"
                      },
                        fontWeight: "normal",
                        padding: {
                          xs: "10px 10px 5px 10px",
                          sm: "10px 10px 5px 10px",
                          md: "0px 5px 5px 5px",
                          lg: "10px 10px 0px 10px",
                          xl: "0px 10px 0px 10px"
                        },
                        backgroundColor:{
                          xs: "rgba(255,255,255,.95)",
                          sm: "rgba(255,255,255,.85)"
                        },

                        borderRadius:"5px"
                          
                    }}
                    >

                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{
                          border:"0px solid orange",
                          width: {
                            xl: "100%",
                            lg: "100%",
                            md: "100%",
                            sm: "100%",
                          },
                          height: {
                            xl: "100%",
                            lg: "100%",
                            md: "100%",
                            sm: "100%",
                          },
                        }}
                        >
                         
                       <Grid
                       item
                       xs={12}
                       sm={12}
                       md={12}
                       lg={12}
                       xl={12}
                       sx={{
                         fontSize:{
                           xs:"1rem",
                           md:"1.25rem",
                           lg:"1.75rem"
                         },
                         height:{
                           xs:"88%",
                           md:"90%",
                           lg:"90%",
                           xl:"90%"
                         },                            
                         color:this.props.theme.bannerColor,
                         border: "0px solid rgba(205,205,205,.85)",
                         paddingBottom:"0px"
                       }}
                       >

                           <Grid
                           container
                           direction="row"
                           justifyContent="center"
                           alignItems="flex-start"
                           sx={{
                             
                             paddingTop:{
                               xl: "10px",
                               lg: "10px",
                               md: "10px",
                               sm: "0px",
                             },
                             paddingBottom:{
                               xl: "10px",
                               lg: "10px",
                               md: "0px",
                               sm: "0px",
                             },
                             width: {
                               xl: "100%",
                               lg: "100%",
                               md: "100%",
                               sm: "100%",
                             },
                             height: {
                               xl: "100%",
                               lg: "100%",
                               md: "100%",
                               sm: "100%",
                               xs: "100%",
                             },
                           }}
                           >
                              <MagazineComponent 
                             
                              parentPage={"Magazine"}
                              magazine={{
                                status:"Available",
                                title:"Spring/Summer 2024",
                                infoPage:"",
                                pages:[
                          "magazine/spring-summer-page-01.png",
                          "magazine/spring-summer-page-02.png",
                          "magazine/spring-summer-page-03.png",
                          "magazine/spring-summer-page-04.png",
                          "magazine/spring-summer-page-05.png",
                          "magazine/spring-summer-page-06.png",
                          "magazine/spring-summer-page-07.png",
                          "magazine/spring-summer-page-08.png",
                          "magazine/spring-summer-page-09.png",
                          "magazine/spring-summer-page-10.png",
                          "magazine/spring-summer-page-11.png",
                          "magazine/spring-summer-page-12.png"
                                ]
                              }}
                              requestMagazine={this.getRequestMagazine}
                              theme={this.props.theme}
                              promoimage={this.props.screenSize !== "xs"? null : "magazine/magazine-promo.jpg"}

                            />
                            
                            

                           </Grid>
                       </Grid>
                           
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          sx={{
                            fontSize:{
                              xs:"1rem",
                              md:"1.5rem",
                              lg:"1.75rem"
                            },
                            
                            height:{
                              xs:"12%",
                              sm:"12%",
                              md:"10%",
                              lg:"10%",
                              xl:"10%",
                            },   
                            padding:"10px",
                            width:"100%",                   
                            color:this.props.theme.bannerTextColor,
                            border: "0px solid rgba(205,205,205,.85)",
                            padding:"0px"
                          }}>

                            <Grid container
                            justifyContent="center"
                            alignItems="center" 
                            sx={{overflowX:"hidden", width:"100%", height:"100%", 
                            paddingBottom:"0px",
                            padding:"5px 0px 5px 0px "
                            
                            }} >
                                    <Box sx={{
                                     color:this.props.theme.bannerColor,
                                     padding:"5px",
                                     fontStyle:"italic",
                                      width:{
                                        xs:"100%",
                                        sm:"100%",
                                        md:"100%",
                                        lg:"100%",
                                        xl:"100%",
                                      }, 
                                      
                                      fontSize:{
                                        xs:".6rem",
                                        sm:".6rem",
                                        md:".6rem",
                                        lg:".8rem",
                                        xl:".8rem",
                                      }, 
                                      border:"0px solid black"
                                      }}>
                                        {this.props.screenSize !== "xs"?
                                        "My pocket guide is free and a great resource as you begin your real estate journey. Send me your request and I'll build you a full guide, personalized to your neighborhood and your home. When your personal pocket guide is ready I'll contact you to let you know its on the way." 
                                        :
                                        "My pocket guide is free, send me your request and I'll build you a full guide, personalized to your neighborhood and your home.  When your guide is ready I'll contact you to let you know its on the way." 
                                        }
                                      </Box> 
                              </Grid>
                          </Grid>

                       </Grid>                       
                    </Grid>
                </Grid>
            </Grid>
         </Grow>     
:
null
}
         {this.props.screenSize !== "xs"?
         <Grow in={true} timeout={4000}>
          <Grid
            item
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              border: "0px solid blue",
              height: {
                xs: "28%",
                sm: "30%",
                md: "93%",
                lg: "93%",
                xl: "93%",
              },
                            
              padding:{
                xs: "5px 5px 0px 5px",
                sm: "5px 5px 0px 5px",
                md: "5px 15px 0px 5px",
                lg: "5px 5px 0px 5px",
                xl: "5px 5px 0px 5px",
              }
            }}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
          >

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                sx={{
                  padding: {
                    xs: "0px 0px 0px 0px",
                    sm: "5px 0px 5px 0px",
                    md: "10px 0px 0px 10px",
                    lg: "25px 0px 0px 20px",
                    xl: "15px 10px 0px 20px"
                  },
                  border:"0px solid red",
                  width: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                  },
                  height: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                }}
                >
                    <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      height: {
                        xl: "95%",
                        lg: "95%",
                        md: "98%",
                        sm: "95%",
                        xs: "95%",
                      },
                      border:{
                        xs:"1px solid black",
                        sm:"1px solid black",
                        md:"2px solid #333333",
                        lg:"2px solid #333333",
                        xl:"2px solid #333333"
                      },
                        fontWeight: "normal",
                        padding: {
                          xs: "0px 0px 0px 0px",
                          sm: "10px 10px 5px 10px",
                          md: "0px 5px 5px 5px",
                          lg: "0px 0px 0px 0px",
                          xl: "0px 0px 5px 0px"
                        },
                        backgroundColor:"rgba(255,255,255,.85)",
                        borderRadius:"5px"
                          
                    }}
                    ref={this.containerRef}
                    >
                       
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            width: {
                              xs: "100%",
                            },
                            height: {
                              xs: "100%",
                            },
                            padding:"0px",
                            border:"0px solid orange",
                            borderRadius:"0px"
                          }}
                          >
                              <Grid
                                item
                                sx={{
                                  paddingTop: {
                                    xs:"5px",
                                    sm:"0px",
                                    md:"5px",
                                    lg:"5px",
                                    xl:"10px",
                                  },    
                                  fontWeight:"bold",
                                  textAlign: "center",
                                  fontSize: {
                                    xs: "1.75rem",
                                    sm: "1.75rem",
                                    md: "2.5rem",
                                    lg: "2.5rem",
                                    xl: "2.5rem",
                                  },
                                height: {
                                    xs: "20%",
                                    sm: "20%",
                                    md: "10%",
                                    lg: "10%",
                                    xl: "10%",
                                  },
                                  border:"0px solid black",
                                  color: this.props.theme.textColor
                                }}
                                xs={8}
                                md={12}

                              >
                                  Donna Wills
                              </Grid> 
                              <Grid
                                  item
                                  sx={{
                                    padding: {
                                      xs:"10px 0px 5px 0px",
                                      sm:"0px",
                                      md:"0px",
                                      lg:"0px",
                                      xl:"0px",
                                    },    
                                    textAlign: "center",
                                    border:"0px solid black",
                                    fontSize: {
                                      xs: ".8rem",
                                      sm: ".8rem",
                                      md: "1rem",
                                      lg: "1rem",
                                      xl: "1rem",
                                    },
                                    
                                  height: {
                                      xs: "10%",
                                      sm: "10%",
                                      md: "10%",
                                      lg: "10%",
                                      xl: "10%",
                                    },
                                    color: this.props.theme.textColor
                                  }}
                                  xs={2}
                                  md={2}
                                >
                                </Grid> 

                                <Grid
                                  item
                                  sx={{
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                    padding: {
                                      xs:"10px 0px 5px 0px",
                                      sm:"0px",
                                      md:"5px 5px 2px 5px",
                                      lg:"2px 5px 5px 5px",
                                      xl:"4px 5px 4px 5px",
                                    },    
                                    textAlign: "center",
                                    border:"0px solid black",
                                    fontSize: {
                                      xs: ".8rem",
                                      sm: ".8rem",
                                      md: ".8rem",
                                      lg: "1rem",
                                      xl: "1rem",
                                    },
                                    
                                  height: {
                                      xs: "10%",
                                      sm: "10%",
                                      md: "5%",
                                      lg: "5%",
                                      xl: "5%",
                                    },
                                    borderRight: "2px solid " + this.props.theme.bannerTextColor,
                                    color: this.props.theme.bannerTextColor,
                                    background: this.props.theme.bannerColor,
                                    borderTopLeftRadius: "20px",
                                    borderTopRightRadius: "0px",
                                    borderBottomRightRadius: "0px",
                                    borderBottomLeftRadius: "20px",
                            
                                  }}
                                  xs={4}
                                  md={4}
                                  onClick = { () => this.showMyServices()}

                                >
                                    About Me
                                </Grid> 
                                <Grid
                                  item
                                  sx={{
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                    padding: {
                                      xs:"10px 0px 5px 0px",
                                      sm:"0px",
                                      md:"5px 5px 2px 5px",
                                      lg:"2px 5px 5px 5px",
                                      xl:"4px 5px 4px 5px",
                                    },    
                                    textAlign: "center",
                                    border:"0px solid black",
                                    fontSize: {
                                      xs: ".8rem",
                                      sm: ".8rem",
                                      md: ".8rem",
                                      lg: "1rem",
                                      xl: "1rem",
                                    },
                                    
                                  height: {
                                      xs: "10%",
                                      sm: "10%",
                                      md: "5%",
                                      lg: "5%",
                                      xl: "5%",
                                    },
                                    color: this.props.theme.bannerTextColor,
                                    background: this.props.theme.bannerColor,
                                    borderTopLeftRadius: "0px",
                                    borderTopRightRadius: "20px",
                                    borderBottomRightRadius: "20px",
                                    borderBottomLeftRadius: "0px",
                                  }}
                                  xs={4}
                                  md={4}
                                    onClick={() => this.showContact("Homepage")}

                                >
                                   Contact Me
                                </Grid> 
                                <Grid
                                  item
                                  sx={{
                                    padding: {
                                      xs:"10px 0px 5px 0px",
                                      sm:"0px",
                                      md:"0px",
                                      lg:"0px",
                                      xl:"0px",
                                    },    
                                    textAlign: "center",
                                    border:"0px solid black",
                                    fontSize: {
                                      xs: ".8rem",
                                      sm: ".8rem",
                                      md: "1rem",
                                      lg: "1rem",
                                      xl: "1rem",
                                    },
                                    
                                  height: {
                                      xs: "10%",
                                      sm: "10%",
                                      md: "10%",
                                      lg: "10%",
                                      xl: "10%",
                                    },
                                    color: this.props.theme.textColor
                                  }}
                                  xs={2}
                                  md={2}
                                >
                                </Grid> 

                                <Grid
                                  item
                                  sx={{
                                    padding: "0px",
                                    textAlign: "center",
                                    fontSize: {
                                      xs: "0rem",
                                      sm: "0rem",
                                      md: "2.5rem",
                                      lg: "3rem",
                                      xl: "3rem",
                                    },
                                  height: {
                                      xs: "0px",
                                      sm: "0px",
                                      md: "60%",
                                      lg: "60%",
                                      xl: "60%",
                                    },

                                  }}
                                  xs={8}
                                  md={12}
                                  >
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                      sx={{
                                        backgroundImage: 'url("profilepics/profile-round.png")',
                                        backgroundPosition: "50% 100%",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        borderRadius:{
                                          xs:"0px",
                                          sm:"0px",
                                          md:"50px",
                                          lg:"50px",
                                          xl:"50px"
                                        },
                                        width: "100%",
                                        height: {
                                          xs:"0px",
                                          sm:"0px",
                                          md:"100%",
                                          lg:"100%",
                                          xl:"100%"
                                        },
                                        padding: "0px",
                                      }}
                                    >
                                  </Grid> 
                                </Grid> 

                                <Grid
                                    item
                                    sx={{
                                      padding: "0px",
                                      textAlign: "center",
                                      fontSize: "1rem",
                                      height: {
                                        xs: "65%",
                                        sm: "65%",
                                        md: "10%",
                                        lg: "10%",
                                        xl: "10%",
                                      },
                                    }}
                                    xs={8}
                                    md={12}
                                 >
                                      <Grid
                                          container
                                          direction="row"
                                          justifyContent="center"
                                          alignItems="flex-start"
                                          sx={{
                                            cursor: "pointer",
                                            width: "100%",
                                            height: "100%",
                                            padding: {
                                              xs:"10px",
                                              sm:"30px",
                                              md:"10px",
                                              lg:"10px",
                                              xl:"10px",
                                            },    
                                            fontWeight: "bold",
                                            border:"0px solid red",
                                            lineHeight: {
                                              xs: "1.5",
                                              sm: "1.5",
                                              md: "1.5",
                                              lg: "1.5",
                                              xl: "1.5",
                                            },
                                            fontSize: {
                                              xs: ".8rem",
                                              sm: ".8rem",
                                              md: "1rem",
                                              lg: "1.2rem",
                                              xl: "1.2rem",
                                            },
                                            color:this.props.theme.textColor
                                          }}
                                        >
                                          Licensed Real Estate Salesperson
                                          <br />
                                          DonnaWills@HowardHanna.com
                                          <br />
                                          315.723.1077
                                        </Grid>

                                </Grid>                                
                                <Grid
                                        item
                                        sx={{
                                          marginTop: "10px",
                                          textAlign: "center",
                                          fontSize: ".8rem",
                                          height: "10%",
                                          border:"0px solid red",
                                          lineHeight: "1.5",

                                        }}
                                        
                                        xs={12}
                                    >
                                      
                                </Grid>                                

                      </Grid>
                     

                </Grid>

                </Grid>
              </Grid>
            </Grow>
            :
            null

         }

            <Grid
                  item
                  sx={{
                    background:this.props.theme.headerColor,
                    color:this.props.theme.headerTextColor,

                    border:"0px solid red",
                    padding: "0px",
                    textAlign: "center",
                    height: {
                      xs: "7%",
                      sm: "7%",
                      md: "7%",
                      lg: "7%",
                      xl: "7%",
                    },
                  }}
                  xs={12}
                >
                  <Footer theme={this.props.theme} />
              </Grid>
          </Grid>
    
    );
  };


  render() {
        return(
        <Fade in={true} timeout={2000}>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,0)",
          height:"100%",
          width:"100%",     
          borderTop: "0px solid gray"
        }}
      >
      <Grid
          item
          sx={{
            
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "15%",
              xs: "20%",
            },
            textAlign:"center",
            background: this.props.theme.headerColor,
            padding: {
              xs: "0px",
              sm:"0px"
            },
            fontSize: "16px",
            borderBottom: {
              xs:"0px solid #AAAAAA",
              sm:"0px solid #AAAAAA",
              md:"1px solid #AAAAAA",
              lg:"1px solid #AAAAAA",
              xl:"1px solid #AAAAAA"
            },
            borderTop: {
              xs:"1px solid #888888",
              sm:"1px solid #888888",
              md:"1px solid #AAAAAA",
              lg:"1px solid #AAAAAA",
              xl:"1px solid #AAAAAA"
            }
          }}
          xs={12}
        >

          <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            height:"100%",
            width:"100%",     
            border: "0px solid #333333"
            
        }}
          >


          {this.props.screenSize == "xs"?
              <React.Fragment>
                 <Grid
                    item
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                      height: {
                        xs: "70%",
                      },
                      textAlign: {
                        xs:"center"
                      },
                      paddingTop: {
                        xs:"0px",
                      },
                      fontSize:{
                        xs:".6rem",      
                      },
                      color: this.props.theme.headerTextColor,
                      border:"0px solid red"
                    }}
                    onClick = { () => this.showAbout()}
                    xs={2}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        height:"80%",
                        width:"100%", 
                        padding:"0px",
                        border:"0px solid black"    
                          }}
                    >
                      <HomeIcon sx={{fontSize:"32px"}}/>
                      </Grid>      
                </Grid>      


                <Grid
                  item
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    textALign:"center",
                    height: {
                      xl: "100%",
                      lg: "100%",
                      md: "100%",
                      xs: "70%",
                    },
                    display: {
                      xs:'flex',
                      sm:'flex',
                      md:'flex',
                      lg:'flex',
                      xl:'flex',
                    } ,    
                    border:"0px solid black"    
                  }}
                  onClick = { () => this.showAbout()}
                  xs={8}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      height:"80%",
                      width:"100%", 
                      border:"0px solid black"    
                        }}
                  >
                    <Image src="logos/loveit-logo-words.png" fit="contain" duration={325} />
                    </Grid>     
                </Grid>
                <Grid
                    item
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                      height: {
                        xs: "70%",
                      },
                      textAlign: {
                        xs:"center"
                      },
                      paddingTop: {
                        xs:"0px",
                      },
                      fontSize:{
                        xs:".6rem",      
                      },
                      color: this.props.theme.headerTextColor,
                      border:"0px solid red"
                    }}
                    onClick = { () => this.showMainMenu()}
                    xs={2}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        height:"80%",
                        width:"100%", 
                        padding:"0px",
                        border:"0px solid black"    
                          }}
                    >
                      <MenuIcon sx={{fontSize:"32px"}}/>
                  </Grid>      
                </Grid>      

                <Grid
                    item
                    sx={{
                      height: {
                        sm: "15%",
                      },
                      border:"0px solid red"

                    }}

                    xs={0}
                  >
                    
                </Grid>      

                <Grid
                    item
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                      height: {
                        sm: "20%",
                      },
                      textAlign: {
                        xs:"center"
                      },
                      padding: {
                        xs:"5px",
                      },
                      fontSize:{
                        xs:".8rem",      
                      },
                      background: this.props.theme.bannerColor,
                      color: this.props.theme.bannerTextColor,
                      fontWeight:"plain",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                      borderBottomRightRadius: "15px",
                      borderBottomLeftRadius: "15px",
                      borderRight:"2px solid white",
                      borderLeft:"2px solid white"
                      
                    }}
                    onClick = { () => this.showMyServices()}
                    xs={6}
                  >
                      My Serivces
                </Grid>      

                <Grid
                    item
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                      height: {
                        sm: "20%",
                      },
                      textAlign: {
                        xs:"center"
                      },
                      padding: {
                        xs:"5px",
                      },
                      fontSize:{
                        xs:".8rem",      
                      },
                      background: this.props.theme.bannerColor,
                      color: this.props.theme.bannerTextColor,
                      fontWeight:"plain",

                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                      borderBottomRightRadius: "15px",
                      borderBottomLeftRadius: "15px",
                      borderLeft:"2px solid white",
                      borderRight:"2px solid white"
                      
                    }}
                    onClick = { () => this.showSearchListings()}
                    xs={6}
                  >
                      Search Listings
                </Grid>      

                <Grid
                   item
                   sx={{
                     height: {
                       sm: "5%",
                     },
                     padding:"5px",
                     border:"0px solid red"
                    }}
                   xs={0}
                 >
                </Grid>      

              </React.Fragment>
                      
            :            
              <React.Fragment>
                <Grid
                  item
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    height: {
                      sm: "100%",
                    },
                    textAlign:"center",
                    paddingTop: {
                      xs:"5px",
                      sm:"5px",
                      md:"10px",
                    },
                    borderTop:
                    {
                      xs:"0px solid white",
                      sm: this.state.showMyServices ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                    },
                    color: this.props.theme.headerTextColor,
                    fontSize:{
                      xs:"0",
                      sm:"0",
                      md:".75rem",
                      lg:".75rem",
                      xl:".75rem",
                    },

                    fontWeight: "bold",
                    
                  }}
                  onClick = { () => this.showMyServices()}
                  xs={2}
                  sm={2}
                  md={1}
                >
                  <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  >
                    <Grid
                        item
                        sx={{
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                      {/*
                        <center>
                        <Image src={"general/my-services.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={25} width={25} duration={500}/>              
                        </center>
                      */}              
                      </Grid>
                      <Grid
                        item
                        sx={{
                          padding:"0px 0px 0px 0px",
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                        My<br/>Services
                      </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    height: {
                      sm: "100%",
                    },
                    textAlign:"center",
                    paddingTop: {
                      xs:"5px",
                      sm:"5px",
                      md:"10px",
                    },
                    borderTop:
                    {
                      xs:"0px solid white",
                      sm: this.state.showFinancing ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                    },
                    fontSize:{
                      xs:"0",
                      sm:"0",
                      md:".75rem",
                      lg:".75rem",
                      xl:".75rem",

                    },
                    fontWeight: "bold",
                    color: this.props.theme.headerTextColor
                  
                }}
                  onClick = { () => this.showFinancing()}
                  xs={0}
                  sm={0}
                  md={1}
                >
                  <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  >
                    <Grid
                        item
                        sx={{
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      > 
                      {/*
                        <center>
                          <Image src={"general/calculator.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={25} width={25} duration={500}/>              
                        </center>
                      */}
                      </Grid>
                      <Grid
                        item
                        sx={{
                          padding:"0px 0px 0px 0px",
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                        Payment<br/>Calculator
                      </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    height: {
                      sm: "100%",
                    },
                    textAlign:"center",
                    paddingTop: {
                      xs:"5px",
                      sm:"5px",
                      md:"10px",
                    },
                    borderTop:
                    {
                      xs:"0px solid white",
                      sm: this.state.showHomeMarketReport ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                    },
                    fontSize:{
                        xs:"0",
                        sm:"0",
                        md:".75rem",
                        lg:".75rem",
                        xl:".75rem",

                      },
                      fontWeight: "bold",
                      color: this.props.theme.headerTextColor

                  }}
                  onClick = { () => this.showHomeMarketReport()}
                  xs={0}
                  sm={0}
                  md={1}
                >
                  <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  >
                    <Grid
                        item
                        sx={{
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                      {/*
                        <center>
                          <Image src={"general/house-transp.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}, textAlign:"center"}} fit="contain" height={25} width={25} duration={500}/>
                        </center>
                      */}              
                      </Grid>
                      <Grid
                        item
                        sx={{
                          padding:"0px 0px 0px 0px",
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                        Home Value<br/>Market Report
                      </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    height: {
                      sm: "100%",
                    },
                    textAlign:"center",
                    paddingTop: {
                      xs:"5px",
                      sm:"5px",
                      md:"10px",
                    },
                    borderTop:
                    {
                      xs:"0px solid white",
                      sm: this.state.showUltimateGuides ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                    },
                    fontSize:{
                        xs:"0",
                        sm:"0",
                        md:".75rem",
                        lg:".75rem",
                        xl:".75rem",
                      },
                      fontWeight: "bold",
                      color: this.props.theme.headerTextColor
                  }}
                  onClick = { () => this.showUltimateGuides()}
                  xs={0}
                  sm={0}
                  md={1}
                >
                  <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  >
                    <Grid
                        item
                        sx={{
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                      </Grid>
                      <Grid
                        item
                        sx={{
                          padding:"0px 0px 0px 0px",
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                        My Ultimate<br/>Guides
                      </Grid>
                  </Grid>
                </Grid>

                {/*
                <Grid
                  item
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    height: {
                      sm: "100%",
                    },
                    textAlign:"center",
                    paddingTop: {
                      xs:"5px",
                      sm:"5px",
                      md:"10px",
                    },
                    borderTop:
                    {
                      xs:"0px solid white",
                      sm: this.state.showOurTeam ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                    },
                    fontSize:{
                        xs:"0",
                        sm:"0",
                        md:".75rem",
                        lg:".75rem",
                        xl:".75rem",

                      },
                      fontWeight: "bold",
                      color: this.props.theme.headerTextColor

                  }}
                  onClick = { () => this.showOurTeam()}
                  xs={0}
                  sm={0}
                  md={1}
                >
                  <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  >
                    <Grid
                        item
                        sx={{
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                      </Grid>
                      <Grid
                        item
                        sx={{
                          padding:"0px 0px 0px 0px",
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                        Our<br/>Team
                      </Grid>
                  </Grid>
                </Grid>
               */}

                <Grid
                  item
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    textALign:"center",
                    height: {
                      xl: "100%",
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                    },
                    padding: "0px",
                    display: {
                      xs:'flex',
                      sm:'flex',
                      md:'flex',
                      lg:'flex',
                      xl:'flex',
                    } ,    
                  }}
                  onClick = { () => this.showAbout()}
                  xs={7}
                  sm={7}
                  md={4}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      height:"100%",
                      width:"100%", 
                      padding:"10px",
                      border:"0px solid black"    
                        }}
                  >
                    
                      <Image src="logos/loveit-logo-words.png" fit="contain" duration={325} />
                    </Grid>     
                </Grid>
                <Grid
                  item
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    height: {
                      sm: "100%",
                    },
                    textAlign:"center",
                    paddingTop: {
                      xs:"5px",
                      sm:"5px",
                      md:"10px",
                    },
                    borderTop:
                    {
                      xs:"0px solid white",
                      sm: this.state.showSearchListings ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                    },
                    fontSize:{
                      xs:"0",
                      sm:"0",
                      md:".75rem",
                      lg:".75rem",
                      xl:".75rem",

                    },
                    fontWeight: "bold",
                    color:  this.props.theme.headerTextColor

                  }}
                  
                  //onClick = { () => this.showHowardHanna()}
                  onClick = { () => this.showSearchListings()}
                  xs={0}
                  sm={0}
                  md={1}
                >

                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  >
                    <Grid
                        item
                        sx={{
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                      {/*
                        <center>
                        <Image src={"general/search.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={25} width={25} duration={500}/>              
                        </center>              
                      */}
                      </Grid>
                      <Grid
                        item
                        sx={{
                          padding:"0px 0px 0px 0px",
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                        Search<br/>Listings
                      </Grid>
                  </Grid>

                </Grid>
                <Grid
                  item
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    height: {
                      sm: "100%",
                    },
                    textAlign:"center",
                    paddingTop: {
                      xs:"5px",
                      sm:"5px",
                      md:"10px",
                    },
                    borderTop:
                    {
                      xs:"0px solid white",
                      sm: this.state.showFavoriteListings ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                    },
                    fontSize:{
                      xs:"0",
                      sm:"0",
                      md:".75rem",
                      lg:".75rem",
                      xl:".75rem",

                    },
                    fontWeight: "bold",
                    color:  this.props.theme.headerTextColor

                  }}
                  xs={0}
                  sm={0}
                  md={1}
                  onClick = { () => this.showFavoriteListings()}
                  >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  >
                    <Grid
                        item
                        sx={{
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                      {/*
                        <center>
                        <Image src={"general/favorites.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={25} width={25} duration={500}/>              
                        </center>              
                      */}
                      </Grid>
                      <Grid
                        item
                        sx={{
                          padding:"0px 0px 0px 0px",
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                        Your<br/>Favorites
                      </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    height: {
                      sm: "100%",
                    },
                    textAlign:"center",
                    paddingTop: {
                      xs:"5px",
                      sm:"5px",
                      md:"10px",
                    },
                    borderTop:
                    {
                      xs:"0px solid white",
                      sm: this.state.showFeaturedListings ? "10px solid " + this.props.theme.bannerColor: "10px solid " + this.props.theme.headerColor
                    },
                    fontSize:{
                      xs:"0",
                      sm:"0",
                      md:".75rem",
                      lg:".75rem",
                      xl:".75rem",

                    },
                    fontWeight: "bold",
                    color:  this.props.theme.headerTextColor

                  }}
                  onClick = { () => this.showFeaturedListings()}
                  xs={0}
                  sm={0}
                  md={1}
                >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  >
                    <Grid
                        item
                        sx={{
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                      {/*
                        <center>
                        <Image src={"general/realestate-sold2.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} fit="contain" height={25} width={25} duration={500}/>              
                        </center>              
                      */}
                      </Grid>
                      <Grid
                        item
                        sx={{
                          padding:"0px 0px 0px 0px",
                          fontSize:".75rem",
                          textAlign:"center"
                        }}
                        xs={12}
                      >  
                        Featured<br/>Listings
                      </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    height: {
                      sm: "100%",
                    },
                    textAlign: {
                      xs:"right",
                      sm:"right",
                      md:"center"
                    },
                    paddingTop: {
                      xs:"5px",
                      sm:"5px",
                      md:"20px",
                    },
                    fontSize:{
                      xs:".6rem",
                      sm:"1rem",
                      md:".75rem",
                      lg:"1rem",
                      xl:".75rem",

                    },
                    color: this.props.theme.headerTextColor,
                    border:"0px solid red"
                  }}
                  onClick = { () => this.showMainMenu()}
                  xs={2}
                  sm={2}
                  md={1}
                >
                    <MenuIcon sx={{fontSize:"32px"}}/>
                </Grid>
          </React.Fragment>

        }
          </Grid>
        </Grid>

       
        <Grid
          item
          sx={{
            
            height: {
              xl: "90%",
              lg: "90%",
              md: "90%",
              sm: "90%",
              xs: "85%",
            },
            padding: "0px",
            border: "0px solid orange",
            backgroundImage: "url('"+ this.props.theme.background +"')",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          xs={12}
        >
          <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: "rgba(255,255,255,0)",
                height: "100%",
                
                border: "0px solid yellow",
                padding:"0px"
              
              }}
            >
             {this.getTeaserTile()}
            </Grid>
        </Grid>
                
        <RequestShowing
          parentPage={this.state.parentPage}
          listings={this.state.listingRequest}
          showRequestShowing={this.state.showRequestShowing}
          requestShowing={this.getRequestShowing}
          toggleRequestShowing={this.toggleRequestShowing}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          favorites={this.state.favorites}
          markAsFavorite={this.markAsFavorite}
        />

        <MyServices
          screenSize ={this.props.screenSize}
          parentPage={this.state.parentPage}
          showMyServices={this.state.showMyServices}
          toggleMyServices={this.toggleMyServices}
          showContact={this.showContact}
          zindex={this.state.myserviceszindex}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          getInformationDownload={this.getInformationDownload}
          getCommunityDownload={this.getCommunityDownload}
          getCommunityReport={this.getCommunityReport}
        />

        <FeaturedListings
          parentPage={this.state.parentPage}
          showFeaturedListings={this.state.showFeaturedListings}
          requestShowing={this.getRequestShowing}
          getFeaturedListings={this.getFeaturedListings}
          toggleFeaturedListings={this.toggleFeaturedListings}
          displayRequestShowing={this.displayRequestShowing}
          getListingReport={this.getListingReport}
          getFinanceDownload={this.getFinanceDownload}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          favorites={this.state.favorites}
          requestid={this.state.requestid}
          markAsFavorite={this.markAsFavorite}
          getPhotoReport={this.getPhotoReport}          

        />

        <Financing
          parentPage={this.state.parentPage}
          showFinancing={this.state.showFinancing}
          toggleFinancing={this.toggleFinancing}
          showContact={this.showContact}
          zindex={this.state.financingzindex}
          getFinanceDownload={this.getFinanceDownload}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />

        <HomeValue
          parentPage={this.state.parentPage}
          showHomeValue={this.state.showHomeValue}
          toggleHomeValue={this.toggleHomeValue}
          showContact={this.showContact}
          zindex={this.state.homevaluezindex}
          getHomeValueReport={this.getHomeValueReport}
          getHomeValueDownload={this.getHomeValueDownload}
          getPlaces={this.getPlaces}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />

        <HomeMarketReport
          parentPage={this.state.parentPage}
          showHomeMarketReport={this.state.showHomeMarketReport}
          toggleHomeMarketReport={this.toggleHomeMarketReport}
          showContact={this.showContact}
          zindex={this.state.homevaluezindex}
          getHomeMarketReport={this.getHomeMarketReport}
          getHomeMarketReportDownload={this.getHomeMarketReportDownload}
          getPlaces={this.getPlaces}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />

        <About
          showAbout={this.state.showAbout}
          toggleAbout={this.toggleAbout}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />


        <FavoriteListings
          parentPage={this.state.parentPage}
          showFavoriteListings={this.state.showFavoriteListings}
          toggleFavoriteListings={this.toggleFavoriteListings}
          displayRequestShowing={this.displayRequestShowing}
          requestShowing={this.getRequestShowing}
          showListingReport={this.showListingReport}
          getFinanceDownload={this.getFinanceDownload}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          favorites={this.state.favorites}
          markAsFavorite={this.markAsFavorite}
          getPhotoReport={this.getPhotoReport}

        />


        <SearchListings
          parentPage={this.state.parentPage}
          showSearchListings={this.state.showSearchListings}
          requestShowing={this.getRequestShowing}
          toggleSearchListings={this.toggleSearchListings}
          showContact={this.showContact}
          getSearchResults={this.getQueryReport}
          zindex={this.state.searchlistingszindex}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          displayRequestShowing={this.displayRequestShowing}
          getFinanceDownload={this.getFinanceDownload}
          favorites={this.state.favorites}
          markAsFavorite={this.markAsFavorite}
          getPhotoReport={this.getPhotoReport}
          
        />

        <BuyingAHome
          parentPage={this.state.parentPage}
          showBuyingAHome={this.state.showBuyingAHome}
          toggleBuyingAHome={this.toggleBuyingAHome}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />

        <SellingAHome
          parentPage={this.state.parentPage}
          showSellingAHome={this.state.showSellingAHome}
          toggleSellingAHome={this.toggleSellingAHome}
          showContact={this.showContact}
          theme={this.props.theme}
          getInformationDownload={this.getInformationDownload}
        />

        <UltimateGuides
          parentPage={this.state.parentPage}
          showUltimateGuides={this.state.showUltimateGuides}
          toggleUltimateGuides={this.toggleUltimateGuides}
          showContact={this.showContact}
          theme={this.props.theme}
          getUltimateGuideDownload={this.getUltimateGuideDownload}
        />

        <OurTeam
          parentPage={this.state.parentPage}
          showOurTeam={this.state.showOurTeam}
          toggleOurTeam={this.toggleOurTeam}
          showContact={this.showContact}
          theme={this.props.theme}
          getInformationDownload={this.getInformationDownload}
        />
       

        <HowardHanna
          parentPage={this.state.parentPage}
          showHowardHanna={this.state.showHowardHanna}
          toggleHowardHanna={this.toggleHowardHanna}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />

        <RecentlySold
          parentPage={this.state.parentPage}
          showRecentlySold={this.state.showRecentlySold}
          toggleRecentlySold={this.toggleRecentlySold}
          recentlySold = {this.state.recentlySold}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />

        <MyServicesRelocation
          parentPage={this.state.parentPage}
          showRelocation={this.state.showRelocation}
          toggleRelocation={this.toggleRelocation}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          getInformationDownload={this.getInformationDownload}
        />

        <FairHousing
          parentPage={this.state.parentPage}
          showFairHousing={this.state.showFairHousing}
          toggleFairHousing={this.toggleFairHousing}
          showContact={this.showContact}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />

        <Contact
          parentPage={this.state.parentPage}
          showContact={this.state.showContact}
          toggleContact={this.toggleContact}
          connect={this.getRequestConnect}
          profilepic={this.state.profilepic}
          name={this.state.name}
          title={this.state.title}
          email={this.state.email}
          phone={this.state.phone}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
          />

        <Cookies
          showCookies={this.state.showCookies}
          toggleCookies={this.toggleCookies}
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />

        <MainMenu
          showMainMenu={this.state.showMainMenu}
          toggleMainMenu={this.toggleMainMenu}
          showContact={this.showContact}
          showMyServices = {this.showMyServices}
          showFinancing = {this.showFinancing}
          showHomeValue = {this.showHomeValue}
          showUltimateGuides = {this.showUltimateGuides}
          showUltimateGuides = {this.showUltimateGuides}
          showHowardHanna = {this.showHowardHanna}
          showRecentlySold = {this.showRecentlySold}
          showRelocation = {this.showRelocation}
          showFairHousing = {this.showFairHousing}
          showAbout = {this.showAbout}
          showFavoriteListings = {this.showFavoriteListings}
          showSearchListings = {this.showSearchListings}
          showFeaturedListings = {this.showFeaturedListings}
          
          theme={this.props.theme}
          downloadResource={this.downloadResource}
        />
        
      </Grid>
      </Fade>
      )
      }
}

export default HomeSearchComponent;
