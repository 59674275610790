import MapComponent from "./MapComponent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from "./Footer";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import MyServicesCommunities from './MyServicesCommunities';
import MyServicesBlog from './MyServicesBlog';
import MyServicesPresentation from './MyServicesPresentation';
import MyServicesMarketing from './MyServicesMarketing';
import MyServicesBuyingSelling from './MyServicesBuyingSelling';
import MyServicesStaging from './MyServicesStaging';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import _ from "lodash";

// Top Marketing
// Strong Negoitaors
// Experiaced Team
// Stagging & Photography
// Resources
// Contact Your Realtor

class MyServices extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      pictureHeight: "100%",
      pictureWidth: "60%",
      parentPage: "MyServices",
      selectedIndex:0,
      testimonialTiles: [
        {
          title: "  Loved Working With Donna",
          summary:
            "I loved working with Donna Wills during my home buying process. She was fun and knowledgeable and always had a positive attitude. Her communication was quick, friendly, and I felt like a priority to her. Highly recommend!",
          name:"Julianna",
        },
        {
          title: "  Donna Is The Best",
          summary:
            "Donna is the best!! As first-time homebuyers, my husband and I were nervous to start our search. But Donna walked us through the process step by step. She created guides and checklists that made comparing homes, submitting an offer, and closing on our home easy. She was always available to answer our questions and give us advice. She truly had our best interests at heart. Donna is a wonderful agent, I can’t recommend her highly enough!",
          name:"Lisa",
        },
        {
          title: "  Donna Was So Helpful",
          summary:
            "Donna was so helpful with our search. She was understanding when we switched our search criteria many times and sent us listings that matched perfectly! She was also always available to us night, day, weekends- you name it. If you want someone reliable , knowledgeable and honest, use her for your next move!",
          name:"Erik",
        },
        {
          title: "  Hard Working",
          summary:
            "Donna always is quick to respond and will never forget to follow up. You would think that she is exclusively yours with your real estate needs, even though you are not haha Obviously, Donna loves what she does.",
          name:"Julie",
        },
        {
          title: "  Above & Beyond",
          summary:
            "After deciding to move across the country We aligned with Donna through an online virtual tour after already having a few tours with other realtors & we knew right away we were meant to be with Donna!",
          name:"Chris & Ashley",
        },
        {
          title: "  Donna Is Amazing",
          summary:
            "Donna is amazing!! She discussed ideas that could enhance my property’s value and went above and beyond with staging the property perfectly and marketing my neighborhood for potential buyers. She earned every penny of her commission which you don’t always see with realtors. And, she was present in the process all the way thru to closing. Highly recommend this gem!",
          name:"Wendy V",
        },
        {
          title: "  Incredibly Smooth",
          summary:
            "The process of selling a home with Donna was incredibly smooth. She not only is incredibly thorough explaining each part of the process, she provided guides for us to understand what came next and while we were moving out, the home looked a little sparse, she ended up bringing in staging furniture so it showed beautifully online! We sold in under one week. We couldn't ask for a better agent!",
          name:"Joe",
        },
        {
          title:"   Glad To Now Call Her A Friend", 
          summary: "Donna went above and beyond to make sure that our family was comfortable and happy during the difficult process of buying and selling! We truly thank her for her time and enthusiasm and are glad to now call her a friend",
          name: "Audrey"
        },
        {
          title:"   Donna Was Great", 
          summary:"Donna was great in helping us find a great home quickly in this crazy market! She went out of her way to do a Zoom showing at 9pm when we were away on what is now our first home. Thank you Donna!",
          name: "Ashley B"   
        },     
        {
        title:"So Very Glad",
        summary: "My husband and I are so very glad that we hired Donna. I originally had a different realtor. He although was nice the first time we met, he seemed to be quiet after.  He was not a good fit for us. Donna was the very exact opposite. She was always with a smile, was so energetic, and so very helpful. She helped stage our house, so that we would get more bids. It worked, as our house went for way more past asking price.",
        name:"Marsha & Tim"
        },
        {
          title: "    Highly Recommend",  
          summary: " She is a “SHARK” That lady is professional in her business. Available 24/7. Always ready to schedule a showing and available to do so any time it works for clients. Either 6 am or 10 pm. Highly recommend!",
          name: "Nelya S"
        },
        {
          title:"    Amazing To Work With",        
          summary:"Donna was amazing to work with. From the beginning to the end of the selling and buying process, Donna was there to advise with any and all questions to help guide us to make the best financial decisions for ourselves. Her knowledge on what to do to get the house picture perfect for the MLS listing as well as for showings was evident. Her detail in staging and wording for the listing was a work of art.",
          name: "Shana M"
        },
        {
          title:"    Great Agent",
          summary:"As a first time homebuyer, Donna made the whole process very easy and exciting. Donna is a great agent and a true expert at what she does. She goes above and beyond what is expected. I highly recommend Donna. She always made herself available whenever I had a question and made sure I was happy.  I am very happy with my experience and I will be working with Donna again in the future.",
          name: "Garth G"
        },
        {
          title:"    Amazing Work Ethic",
          summary:"Donna went above and beyond! She made the home buying process easier then the average agent. She was always available and has AMAZING work ethic. I felt loved and trusted her to find the right home for my family. We could not be happier!!!! Thanks Donna, I will gladly tell my friends and my family who to sign with!",
          name: "Bettina P"
        },
        {
          title:"Donna Was So Patient",
          summary:"As a first time home buyers, my husband and I had many questions. Donna was so patient and able to help us through each question and detail through this process. We went to see so many houses with Donna and she was very flexible with times that worked for us and helped us figure out exactly what we were looking for. I would recommend Donna to anyone looking to buy a home!",
          name:"Erin"
        },
        {
          title:"    There When I Needed Her",
          summary:"Ok so I just want to say Donna wills is amazing!! She was there when I needed her and she answered every question I had to ask.  She deserves so much credit and I don’t know if it can get any better then her she definitely was greatness. It feels like she was a real estate agent sent from heaven. Again really appreciate her and will be using her for my house and rentals from now on.",
          name: "Khiry S"
        },
        {
          title:"    We Were So Impressed",
          summary:"My wife and I's experience with Donna started when she dropped a personalized flier in our mailbox to list our home. We knew we were moving, but had not yet decided to go with another realtor that helped us in the past or look for a new one. We were so impressed with her initiative we called her that night (Sunday). She made time for us that night, spent almost 3 hrs with us and we set a plan to list the home.",
          name: "Jeremy & Erika"
        },
        {
          title:"    Loves What She Does",
          summary: "Donna was a Pleasure to have as our realtor. She was very personable, understanding, hard working, very timely. Donna was alwasy quick to respond and will never forget to follow up.  Donna loves what she does. She always walked that extra mile to make the whole real estate experience with her the best it can be. I cant compliment her and thank her enough! Thank you Donna!",
          name:"Julie & Don"
        },
        {
          title:"    Worked With My Schedule",
          summary:"Donna made the home buying experience as painless as possible. She was extremely responsive, very knowledgable, and worked with my schedule while still being available. I was working on a tight deadline to find a place to move and Donna made sure we met that deadline while also meeting my goals and expectations for a home. I can’t recommend her enough!",
          name:"Anthony B"
        },        
        {
          title:"    Donna is a wonderful agent",
          summary:"Donna is the best!! As first-time homebuyers, my husband and I were nervous to start our search. But Donna walked us through the process step by step. She created guides and checklists that made comparing homes, submitting an offer, and closing on our home easy. She was always available to answer our questions and give us advice. She truly had our best interests at heart. Donna is a wonderful agent, I can’t recommend her highly enough!",
          name:"Lisa",
        },



      ],     

      showPresentation: false,
      showCommunities: false,
      showAboutMe: false,
      showBlog: false,
      showMarketing: false,
      showNegotiation: false,
      showStaging: false,
      subService: "My Services",            
    }
  }


  componentDidUpdate(prevProps, prevState) {
      if (prevProps.showMyServices !== this.props.showMyServices) {
        this.setState({
          showPresentation: false,
          showCommunities: false,
          showAboutMe: false,
          showBlog: false,
          showMarketing: false,  
          showNegotiation: false,  
          showStaging: false,  
          subService:"My Services"  
        })
      }
  }


  toggleMyServices = () => {
    this.setState({
      MyServices: !this.state.MyServices,
      subService:"My Services"
    });
  };

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };

  toggleSubServices = () => {
    this.setState({
      showPresentation: false,
      showCommunities: false,
      showBlog: false,
      showMarketing: false,  
      showNegotiation: false,  
      showStaging: false,  
      subService: "My Services"
    })
  }

  togglePresentation = () => {
    this.setState({
      showPresentation: !this.state.showPresentation,
      showCommunities: false,
      showBlog: false,
      showMarketing: false,  
      showNegotiation: false,  
      showStaging: false,  
      subService: !this.state.showPresentation ? "Full Report" : "My Services"
    })
  }

  toggleCommunities = () => {
    this.setState({
      showPresentation: false,
      showCommunities: !this.state.showCommunities,
      showBlog: false,
      showMarketing: false,  
      showNegotiation: false,  
      showStaging: false,  
      subService: !this.state.showCommunities ? "Insights" : "My Services"
    })
  }

  toggleMarketing = () => {
    this.setState({
      showPresentation: false,
      showCommunities: false,
      showMarketing: !this.state.showMarketing,  
      showBlog: false,
      showNegotiation: false,  
      showStaging: false,  
      subService: !this.state.showMarketing ? "Marketing" : "My Services"
    })
  }

  toggleBlog = () => {
    this.setState({
      showPresentation: false,
      showCommunities: false,
      showMarketing: false,
      showBlog: !this.state.showBlog,
      showNegotiation: false,
      showStaging: false,  
      subService: !this.state.showBlog ? "Guidance" : "My Services"
    })
  }

  toggleNegotiation = () => {
    this.setState({
      showPresentation: false,
      showCommunities: false,
      showMarketing: false,
      showBlog: false,
      showNegotiation: !this.state.showNegotiation,
      showStaging: false,  
      subService: !this.state.showNegotiation ? "Buying & Selling" : "My Services"
    })
  }

  toggleStaging = () => {
    this.setState({
      showPresentation: false,
      showCommunities: false,
      showMarketing: false,
      showBlog: false,
      showNegotiation: false,
      showStaging: !this.state.showStaging,  
      subService: !this.state.showStaging ? "Staging" : "My Services"
    })
  }

  downloadResources = (filename) => {
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }


  createSection = (section, title, intro, tileBuilder, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,1)",
          height: "100%",
          width: "100%",
          borderBottom: "0px solid #333333",
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "8%",
              lg: "8%",
              md: "8%",
              sm: "7%",
              xs: "15%",
            },
            padding: "0px",
            fontSize: "2rem",
            borderBottom: "0px solid red",
            background:this.props.theme.bannerColor
          }}
          xs={12}
        >

      <Grid
              container
              ref={section}
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{          
                padding:"0px",
                borderBottom:"0px solid #AAAAAA",
                background: this.props.theme.bannerColor,
                width: "100%",
                height:"100%"
              }}
            >
                  <Button
                      onClick={this.togglePresentation}
                      variant={this.props.theme.buttonVariant}
                      InputProps={{
                        style:{
                          borderBottom:"1px soild #AAAAAA",
                      }}}
                      sx={{
                        '&:hover': {
                          border: this.state.showPresentation ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,
                          color: this.props.theme.bannerTextColor,
                          background:this.props.theme.buttonHoverColor
                        },

                        marginRight:"0px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        textTransform: "none",
                        textAlign: "left",
                        padding:{
                          xs: "10px 5px 10px 5px",
                          sm: "15px 15px 10px 15px",
                          md: "15px 15px 15px 10px",
                          lg: "10px 15px 8px 10px",
                          xl: "10px 15px 8px 10px",
                        },
                        background: this.state.showPresentation ? this.props.theme.bannerColor : "#EEEEEE",
                        color: this.state.showPresentation ? this.props.theme.bannerTextColor :"#333333",
                        border: this.state.showPresentation ? "1px solid " + this.props.theme.bannerColor : "1px solid "+this.props.theme.bannerColor,      
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        width:{
                          xs:"50%",
                          md:"17%"
                        },
                        height:{
                          md:"100%"
                        }
                      }}
                    >
                       Your Full Report
                    </Button>
                  
                  <Button
                      onClick={this.toggleCommunities}
                      variant={this.props.theme.buttonVariant}
                      InputProps={{
                        style:{
                          borderBottom:"1px soild #AAAAAA",
                      }}}
                      sx={{
                        '&:hover': {
                          border: this.state.showCommunities ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,
                          color: this.props.theme.bannerTextColor,
                          background:this.props.theme.buttonHoverColor,
                        },

                        marginRight:"0px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        textTransform: "none",
                        textAlign: "left",
                        padding:{
                          xs: "10px 5px 10px 5px",
                          sm: "15px 15px 10px 10px",
                          md: "15px 15px 15px 10px",
                          lg: "10px 15px 8px 10px",
                          xl: "10px 15px 8px 10px",
                        },
                        background: this.state.showCommunities ? this.props.theme.bannerColor : "#EEEEEE",
                        color: this.state.showCommunities ? this.props.theme.bannerTextColor :"#333333",
                        border: this.state.showCommunities ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,      
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        width:{
                          xs:"50%",
                          md:"17%"
                        },
                        height:{
                          
                          md:"100%"
                        }

                      }}
                    >
                        Local Insights
                    </Button>
                    
                    <Button
                      onClick={this.toggleMarketing}
                      variant={this.props.theme.buttonVariant}
                      InputProps={{
                        style:{
                          borderBottom:"1px soild #AAAAAA",
                      }}}
                      sx={{
                        '&:hover': {
                          border: this.state.showMarketing ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,
                          color: this.props.theme.bannerTextColor,
                          background:this.props.theme.buttonHoverColor
                        },

                        marginRight: "0px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottom:"1px solid #AAAAAA",
                        textTransform: "none",
                        textAlign: "left",
                        padding:{
                          xs: "10px 5px 10px 5px",
                          sm: "15px 15px 10px 10px",
                          md: "15px 15px 15px 10px",
                          lg: "10px 15px 8px 10px",
                          xl: "10px 15px 8px 10px",
                        },
                        background: this.state.showMarketing ? this.props.theme.bannerColor : "#EEEEEE",
                        color: this.state.showMarketing ? this.props.theme.bannerTextColor :"#333333",
                        border: this.state.showMarketing ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,      
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        width:{
                          xs:"50%",
                          md:"16%"
                        },
                        height:{
                          
                          md:"100%"
                        }

                      }}
                    >
                        Marketing
                    </Button>

                    <Button
                      onClick={this.toggleStaging}
                      variant={this.props.theme.buttonVariant}
                      InputProps={{
                        style:{
                          borderBottom:"1px soild #AAAAAA",
                      }}}
                      sx={{
                        '&:hover': {
                          border: this.state.showStaging ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,
                          color: this.props.theme.bannerTextColor,
                          background:this.props.theme.buttonHoverColor
                        },

                        marginRight: "0px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottom:"1px solid #AAAAAA",
                        textTransform: "none",
                        textAlign: "left",
                        padding:{
                          xs: "10px 5px 10px 5px",
                          sm: "15px 15px 10px 10px",
                          md: "15px 15px 15px 10px",
                          lg: "10px 15px 8px 10px",
                          xl: "10px 15px 8px 10px",
                        },
                        background: this.state.showStaging ? this.props.theme.bannerColor : "#EEEEEE",
                        color: this.state.showStaging ? this.props.theme.bannerTextColor :"#333333",
                        border: this.state.showStaging ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,      
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        width:{
                          xs:"50%",
                          md:"16%"
                        },
                        height:{
                          
                          md:"100%"
                        }

                      }}
                    >
                        Staging
                    </Button>

                    <Button
                      onClick={this.toggleNegotiation}
                      variant={this.props.theme.buttonVariant}
                      InputProps={{
                        style:{
                          borderBottom:"1px soild #AAAAAA",
                      }}}
                      sx={{
                        '&:hover': {
                          border: this.state.showNegotiation ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,
                          color: this.props.theme.bannerTextColor,
                          background:this.props.theme.buttonHoverColor
                        },
            
                        marginRight: "0px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottom:"1px solid #AAAAAA",
                        textTransform: "none",
                        textAlign: "left",
                        padding:{
                          xs: "10px 5px 10px 5px",
                          sm: "15px 15px 10px 10px",
                          md: "15px 15px 15Spx 10px",
                          lg: "10px 15px 8px 10px",
                          xl: "10px 15px 8px 10px",
                        },
                        background: this.state.showNegotiation ? this.props.theme.bannerColor : "#EEEEEE",
                        color: this.state.showNegotiation ? this.props.theme.bannerTextColor :"#333333",
                        border: this.state.showNegotiation ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,      
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        width:{
                          xs:"50%",
                          md:"18%"
                        },
                        height:{
                          
                          md:"100%"
                        }

                    }}
                    >
                        Buying & Selling
                    </Button>

                    <Button
                      onClick={this.toggleBlog}
                      variant={this.props.theme.buttonVariant}
                      InputProps={{
                        style:{
                          borderBottom:"1px soild #AAAAAA",
                      }}}
                      sx={{
                        '&:hover': {
                          border: this.state.showBlog ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,
                          color: this.props.theme.bannerTextColor,
                          background:this.props.theme.buttonHoverColor
                        },
            
                        marginRight: "0px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottom:"1px solid #AAAAAA",
                        textTransform: "none",
                        textAlign: "left",
                        padding:{
                          xs: "10px 5px 10px 5px",
                          sm: "15px 15px 10px 10px",
                          md: "15px 15px 15px 10px",
                          lg: "10px 15px 8px 10px",
                          xl: "10px 15px 8px 10px",
                        },
                        background: this.state.showBlog ? this.props.theme.bannerColor : "#EEEEEE",
                        color: this.state.showBlog ? this.props.theme.bannerTextColor :"#333333",
                        border: this.state.showBlog ? "1px solid " + this.props.theme.bannerColor : "1px solid " + this.props.theme.bannerColor,      
                        fontSize:{
                          xs:".75rem",
                          sm:".75rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        },
                        width:{
                          xs:"50%",
                          md:"16%"
                        },
                        height:{
                          
                          md:"100%"
                        }

                      }}
                    >
                        Guidance
                    </Button>

        </Grid>

        </Grid>
        
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "82%",
              lg: "82%",
              md: "80%",
              sm: "83%",
            },
            padding: "0px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "white",
              height: "100%",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                width: "100%",
                height: {
                  xl: "5%",
                  lg: "5%",
                  md: "10%",
                  sm: "10%",
                },
                fontWeight: "bold",
                paddingTop: {
                  xs:"25px",
                  md:"10px",
                  lg:"10px",
                  xl:"10px",
                },
                paddingBottom:{
                  xs:"20px",
                  md:"10px",
                  lg:"15px",
                  xl:"15px",
                },
                fontSize: {
                  xs:"1.25rem",
                  md:"1.25rem",
                  lg:"1.5rem"
                },
                borderBottom:"0px solid " + this.props.theme.bannerColor,
                color:this.props.theme.bannerColor,
              }}
              xs={12}
            >
              I'll Be With You Every Step Of The Way
            </Grid>    
            <Grid 
              item
              sx={{
                padding: "0px",
                height: {
                  sm: "60%",
                  md: "0%",
                },
                textAlign:"right"
              }}
              xs={12}
              sm={12}
              md={0}
              lg={0}
              >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundImage: 'url("profilepics/profile-round-lowres.png")',
                        backgroundPosition: "50% 100%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        borderRadius:{
                          xs:"50px",
                          sm:"50px",
                          md:"0px",
                          lg:"0px",
                          xl:"0px"
                        },
                        width: "100%",
                        height: {
                          xs:"250px",
                          sm:"300px",
                          md:"80%",
                          lg:"0%",
                          xl:"0%"
                        },
                        paddingTop: "0px",
                      }}
                    >
                  </Grid> 
            </Grid>   
            <Grid
              item
              sx={{
                textAlign:"center",
                height: {
                  sm: "50%",                              
                  md: "55%",                              
                  lg: "50%",                              
                  xl: "60%",                              
                },
               
              }}
              xs={12}
              sm={12}
              md={2}
              lg={2}
            >
              
            </Grid>     
            <Grid
              item
              sx={{
                textAlign:"center",
                height: {
                  sm: "50%",                              
                  md: "55%",                              
                  lg: "50%",                              
                  xl: "60%",                              
                },
              }}
              xs={12}
              sm={12}
              md={7}
              lg={8}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  textAlign:"justify",
                  fontWeight: "normal",
                  width: "100%",
                  height: "100%",
                  padding: {
                    xs:"10px 20px 10px 10px",
                    md:"5px 0px 5px 20px",
                    lg:"10px 50px 10px 50px"
                  },
                  lineHeight:"1.5",
                  color:this.props.theme.textColor,
                  fontSize:{
                    xs:"1rem",
                    md:".75rem",
                    lg:".85rem",
                  }
                }}
              >
                      I am committed to providing you 
                      with personalized attention and expert advice to help you achieve 
                      your real estate goals. With a deep understanding of the local 
                      market, contract procedures and the negotiation process, I will work to ensure that your journey is as smooth and 
                      stress-free as possible. 
                  <br/><br/> 
                      For my sellers, I pride myself on my ability to assess sales trends and to provide  
                      accurate market and home valuations you can use to make informed decisions.  
                      When you decide to list with me you'll get access to all my services, including custom marketing and staging 
                      help to showcase your home to its full potential.
                  <br/><br/>    
                      For my buyers, When you work with me you will also get access to all my services that I'll use to find homes that 
                      align seamlessly with your preferences and lifestyle. 
                      When we find your perfect home I'll negotiate on your
                      behalf which includes handling your contract from initial signing all the way through closing.
                  <br/><br/>   
                      I'll be with you every step of the way.                
              </Grid>
            </Grid>

            <Grid 
              item
              sx={{
                padding: "0px",
                height: {
                  sm: "50%",
                  md: "55%",
                  lg: "60%",
                },
                borderBottom:"0px solid #CCCCCC",
                textAlign:"center"
              }}
              xs={0}
              sm={0}
              md={3}
              lg={2}
              >
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{
                  fontWeight: "normal",
                  width: "100%",
                  height: "100%",
                  backgroundImage: 'url("profilepics/profile-round-lowres.png")',
                  backgroundPosition: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              >
              </Grid>
            </Grid>
            <Grid 
              item
              sx={{
                padding: "0px",
                height: {
                  sm: "40%",
                  md: "40%",
                  lg: "40%",
                  xl: "35%",

                },
                borderTop:"1px solid #DDDDDD",
                borderBottom:"1px solid #DDDDDD",
                textAlign:"left"
              }}
              xs={12}
              >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  fontWeight: "normal",
                  width: "100%",
                  height: "100%",
                  padding: "0px",
                  border:"0px solid rad"
                }}
              >
                {tileBuilder}
              </Grid>
            </Grid>
          </Grid>



        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "12%",
              sm: "10%",
            },
            padding: "5px",
            fontSize: "2rem",
            
          }}
          xs={12}
        >
           <Footer theme={this.props.theme}  />
        </Grid>
      </Grid>
    );
  };


  nextSlide = () => {
    
    if(this.state.testimonialTiles && (this.state.testimonialTiles.length > 0 )){
      if(this.state.selectedIndex+1 < (this.state.testimonialTiles.length)){
        this.setState({
          selectedIndex: this.state.selectedIndex+1
        })
      }else{
        this.setState({
          selectedIndex: 0
        })
      }
    }
  }

  previousSlide = () => {
    if(this.state.testimonialTiles && this.state.testimonialTiles.length > 0){
      if(this.state.selectedIndex > 0){
        this.setState({
          selectedIndex: this.state.selectedIndex-1
        })
      }else{
        this.setState({
          selectedIndex: this.state.testimonialTiles.length-1
        })
      }
    }
  }


  getTestimonialTiles = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              sm: "100%",
            },
            border: "0px solid red",

            background: this.props.theme.bannerColor,
          }}
          xl={2}
          lg={2}
          md={2}
          sm={12}
          xs={6}
        >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: "rgba(255,255,255,0)",
                height:"100%",
                width:"100%",     
                border: "0px solid red",
              }}
            >
            <Button
              onClick={() => this.previousSlide()}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.bannerColor,
                  background:this.props.theme.buttonHoverColor
                },
    
                width:"25%",
                height:"25%",
                background: this.props.theme.bannerColor,
                color:this.props.theme.bannerTextColor,
                border:"1px solid " + this.props.theme.bannerTextColor,
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px"
                },
                marginRight: "0px",
                borderTopLeftRadius: "50px",
                borderTopRightRadius: "50px",
                borderBottomRightRadius: "50px",
                borderBottomLeftRadius: "50px",
                textTransform: "none",
                textAlign: "center",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.75rem",
                  lg:"1.75rem",
                  xl:"1.75rem",
                }
              }}
            >
              <ArrowBackIcon sx={{fontWeight:"bold"}}/>
            </Button>
          </Grid>
        </Grid>

        {this.props.screenSize == "xs"?
          <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              sm: "100%",
            },
            background: this.props.theme.bannerColor
          }}
          xl={2}
          lg={2}
          md={2}
          sm={12}
          xs={6}
        >

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: this.props.theme.bannerColor,
                height:"100%",
                width:"100%",     
                border: "0px solid #AAAAAA",
              }}
            >
            <Button
              onClick={() => this.nextSlide()}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.bannerColor,
                  background:this.props.theme.buttonHoverColor
                },
    
                width:"25%",
                height:"25%",
                color:this.props.theme.bannerTextColor,
                background: this.props.theme.bannerColor,
                border:"1px solid " + this.props.theme.bannerTextColor,
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px"
                },
                marginRight: "0px",
                borderTopLeftRadius: "50px",
                borderTopRightRadius: "50px",
                borderBottomRightRadius: "50px",
                borderBottomLeftRadius: "50px",
                textTransform: "none",
                textAlign: "center",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.75rem",
                  lg:"1.75rem",
                  xl:"1.75rem",
                }
              }}
            >
              <ArrowForwardIcon sx={{fontWeight:"bold"}}/>
            </Button>

          </Grid>
        </Grid>


            : null
        }

        <Grid
          item
          sx={{
            
            padding: "5px 10px 5px 10px",
            height: {
              sm: "100%",
            },
            background: this.props.theme.bannerColor,
          }}
          xl={8}
          lg={8}
          md={8}
          sm={12}
          xs={12}
        >

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                fontSize:"1.2rem",
                background: "rgba(255,255,255,0)",
                height:"100%",
                width:"100%",     
                border: "0px solid #AAAAAA",
                color:this.props.theme.bannerTextColor,
                fontWeight:"bold"
              }}
            >
              <div>
                <div style={{fontSize:"1.2rem", fontWeight:"bold"}}>
                  {this.state.testimonialTiles[this.state.selectedIndex].title}
                </div>                
                <div style={{fontSize:".8rem", fontWeight:"normal"}}>
                  <br/>
                  {this.state.testimonialTiles[this.state.selectedIndex].summary}
                </div>                
                <div style={{fontSize:".8rem", fontWeight:"normal", float:"right"}}>
                  <br/>
                  {this.state.testimonialTiles[this.state.selectedIndex].name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>                
              </div>                
          </Grid>
          </Grid>

          {this.props.screenSize == "xs"? null
          :
          <Grid
            item
            sx={{
              
              padding: "10px 10px 0px 10px",
              height: {
                sm: "100%",
              },
              background: this.props.theme.bannerColor
            }}
            xl={2}
            lg={2}
            md={2}
            sm={12}
            xs={6}
          >

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: "rgba(255,255,255,0)",
                height:"100%",
                width:"100%",     
                border: "0px solid #AAAAAA",
              }}
            >
            <Button
              onClick={() => this.nextSlide()}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.bannerColor,
                  background:this.props.theme.buttonHoverColor
                },
    
                width:"25%",
                height:"25%",
                color:this.props.theme.bannerTextColor,
                background: this.props.theme.bannerColor,
                border:"1px solid " + this.props.theme.bannerTextColor,
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px"
                },
                marginRight: "0px",
                borderTopLeftRadius: "50px",
                borderTopRightRadius: "50px",
                borderBottomRightRadius: "50px",
                borderBottomLeftRadius: "50px",
                textTransform: "none",
                textAlign: "center",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.75rem",
                  lg:"1.75rem",
                  xl:"1.75rem",
                }
              }}
            >
              <ArrowForwardIcon sx={{fontWeight:"bold"}}/>
            </Button>

          </Grid>
          </Grid>
          }
          </React.Fragment>
        )
  }

  render() {

 
    return (
      <React.Fragment>
      <Drawer
          anchor="bottom"
          open={this.props.showMyServices}
          onClose={this.props.toggleMyServices}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              backgroundImage: "url('" + this.props.theme.background + "')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
                  height: {
                xs: "88%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              marginLeft: "auto",
              pointerEvents:"auto",
              border: "0px solid gray"
            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"0px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "5px",
              borderBottom: "0px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={6}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                padding: "0px 0px 0px 5px",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem",
                },             
                color:this.props.theme.bannerTextColor  
              }}
            >
            {this.state.subService}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "center",
              padding: "10px",
              borderBottom: "0px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={6}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact("MyServices")}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  marginRight: "5px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.state.showPresentation || this.state.showCommunities || this.state.showStaging || this.state.showMarketing || this.state.showBlog || this.state.showNegotiation ? this.toggleSubServices : this.props.toggleMyServices}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  marginRight: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "0px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.MyServicesAboutMeSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top MyServicesAboutMeage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                this.getTestimonialTiles(),
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}
            </Grid>
        </Grid>

      </Grid>

      </Drawer>
      
            <MyServicesPresentation
              parentPage={this.state.parentPage}
              showPresentation={this.state.showPresentation}
              togglePresentation={this.togglePresentation}
              showContact={this.props.showContact}
              downloadResource={this.props.downloadResource}
              theme={this.props.theme}
              getInformationDownload={this.props.getInformationDownload}
            />

            <MyServicesCommunities
              parentPage={this.state.parentPage}              
              showCommunities={this.state.showCommunities}
              toggleCommunities={this.toggleCommunities}
              showContact={this.showContact}
              downloadResource={this.props.downloadResource}
              theme={this.props.theme}
              getCommunityReport={this.props.getCommunityReport}
              getCommunityDownload={this.props.getCommunityDownload}
            />

            <MyServicesMarketing
              parentPage={this.state.parentPage}
              showMarketing={this.state.showMarketing}
              toggleMarketing={this.toggleMarketing}
              showContact={this.showContact}
              downloadResource={this.props.downloadResource}
              theme={this.props.theme}
              getInformationDownload={this.props.getInformationDownload}
            />

            <MyServicesStaging
              parentPage={this.state.parentPage}
              showStaging={this.state.showStaging}
              toggleStaging={this.toggleStaging}
              showContact={this.showContact}
              downloadResource={this.props.downloadResource}
              theme={this.props.theme}
              getInformationDownload={this.props.getInformationDownload}
            />

     
            <MyServicesBuyingSelling
              parentPage={this.state.parentPage}
              showNegotiation={this.state.showNegotiation}
              toggleNegotiation={this.toggleNegotiation}
              showContact={this.showContact}
              downloadResource={this.props.downloadResource}
              theme={this.props.theme}
              getInformationDownload={this.props.getInformationDownload}
            />

            <MyServicesBlog
              parentPage={this.state.parentPage}
              showBlog={this.state.showBlog}
              toggleBlog={this.toggleBlog}
              showContact={this.showContact}
              downloadResource={this.props.downloadResource}
              theme={this.props.theme}
              getInformationDownload={this.props.getInformationDownload}
            />
             
        </React.Fragment>          
    );
  }
}

export default MyServices;
